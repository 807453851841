/**
 * Vue Store that holds data of a Project which user selected.
 *
 * @author Documill
 */

import i18n from './../../i18n';

import apiCall from './../../utils/api';
import Collaborators from '../../utils/collaborators';
import Commons from '../../utils/commons';
import { logger } from '../../utils/logger';
import Phases from '../../utils/phases';
import Projects from '../../utils/projects';
import Steps from '../../utils/steps';
import Tasks from '../../utils/tasks';
import debounce from 'lodash/debounce';
import Moment from 'moment';

export default {

  //
  //
  // Start: State
  //
  //

  state: {
    /**
     * The data of a Project that is being accessed by user.
     *
     * How to use:
     * this.$store.state.project.project
     */
    project: {},

    /**
     * If current project's name is duplicate
     *
     * Structure:
     * {
     *  "duplicateStatuses": [
     *    {
     *     "projectStatus",
     *     "projectCount"
     *    }],
     *  "projectId": String,
     *  "projectNameDuplicate": boolean
     * }
     */
    projectNameDuplicate: {},

    /**
     * Available collaborator/task roles per a step type in current
     * Project.
     *
     * Structure:
     * {
     *    "step-type-1": [
     *      "role-1",
     *      "role-2"
     *    ],
     *    "step-type-2": [
     *      "role-2",
     *      "role-3"
     *    ]
     * }
     *
     * How to use:
     * this.$store.state.project.collaboratorRoles
     */
    collaboratorRoles: {},

    /**
     * The identified user's data.
     *
     * How to use:
     * this.$store.state.project.identifiedUser
     */
    identifiedUser: {},

    scheduledProjectReloadCanceled: null,
  },

  //
  //
  // End: State
  //
  //

  //
  //
  // Start: Getters
  //
  //

  getters: {
    /**
     * Tests whether all Sign Steps have Collaborators with valid information.
     *
     * Sign Steps can't be signed by External Collaborators if Project Owner has not defined
     * "name" and "organization" for the External Collaborators.
     *
     * See DOS-1705 for more information.
     *
     * How to use:
     * this.$store.getters.allSignerDetailsValid
     *
     * @returns {Boolean} whether signer's have enough data
     */
    allSignerDetailsValid: (state,getters) => {
      let anchors = state.project.anchors;
      if(anchors && anchors.length > 0) {
        for(let i = 0; i < anchors.length; i++) {
          let anchor = anchors[i];

          let signSteps = anchor.steps.filter(step => step.goal === Steps.TYPES.SIGN);
          for(let s = 0; s < signSteps.length; s++) {
            let step = signSteps[s];

            // If Step allows Signers to define their own names then we consider it valid even
            // though Collaborator does not have signer details.
            // PO should fill organization field when identity verification is enabled. See DOS-3911.
            if(step.signerOverrideAllowed === true && step.requireSignerIdentityVerification === false)
              continue;

            let missingSignerDetails = getters.getStepTasks(step.id).some(task => {
              let collaborator = getters.getCollaboratorById(task.collaboratorId);

              let signerDetails = collaborator != null ? collaborator.signerDetails : null;

              // If Step requires Signers to verify their identity then we consider it valid even
              // though Collaborator does not have signer details. But still check the organization information
              // as it does not obtained while identification

              if(signerDetails == null)
                return true;

              if(step.requireSignerIdentityVerification === true && task.signingBehalfOrganizationEnabled === false)
                return false;

              if(step.requireSignerIdentityVerification === true && signerDetails.organization)
                return false;

              // See DOS-1705. We consider Collaborator to be missing Signer details if he/she is
              // missing either full name or organization. "Title" is optional value.
              if(task.signingBehalfOrganizationEnabled)
                return !Commons.isValidInput(signerDetails.fullName) || !Commons.isValidInput(signerDetails.organization);
              // DOS-2314: Name is required field.
              else
                return !Commons.isValidInput(signerDetails.fullName);
            });

            if(missingSignerDetails)
              return false;
          }
        }
      }

      return true;
    },

    /**
     * Tests whether all Project's Steps have been assigned at least to a single Collaborator.
     *
     * This is usually used to check whether project can go live.
     *
     * How to use:
     * this.$store.getters.allStepsAssigned
     *
     * @returns {Boolean} whether all project's steps have been assigned
     */
    allStepsAssigned: (state,getters) => {
      let anchors = state.project.anchors;
      if(anchors && anchors.length > 0) {
        for(let i = 0; i < anchors.length; i++) {
          let anchor = anchors[i];

          // Try to find a Step that does not have any Tasks, i.e. no Collaborators have been
          // assigned to the Step.

          let missingTasks = anchor.steps.some(step => {
            return getters.getStepTasks(step.id).length === 0;
          });

          if(missingTasks)
            return false; // At least one Step does not have Collaborators assigned.
        }
      }

      return true; // All Steps have Collaborators assigned.
    },

    /**
     * Prepare the Phases data so that it is ready to be displayed in the UI.
     * The list is expected to be in proper order already.
     *
     * How to use:
     * this.$store.getters.projectPhases
     */
    projectPhases: (state) => {
      let phases = state.project.phases; // raw Phase data

      // renaming object properties that don't match
      // with the name referred in the UI
      if (phases) {
        phases.forEach((phase) => {
          phase.type = phase.phaseType;
          phase.name = phase.customName;
        });
      }

      return phases;
    },

    /**
     * Prepare the Tasks data.
     *
     * Notice that this returns copies of the taks and modifying these objects do not make
     * changes to the project data in state.
     *
     * How to use:
     * this.$store.getters.projectTasks
     */
    projectTasks: (state, getters) => {
      let tasks = [];

      if(state.project.tasks === undefined)
        return [];

      state.project.tasks.forEach(projectTask => {
        let task = {};
        let step = getters.getStepByID(projectTask.stepId);
        let phase = getters.getPhaseByID(step.phaseRef);
        let anchor = getters.getAnchorByID(step.stepAnchor);

        // Gets revisions of all documents that were attached to the anchor

        task.id = projectTask.id;

        task.anchorId = anchor.id;
        task.phaseId = step.phaseRef;
        task.stepId = step.id;

        task.documentsRevisions = getters.getAnchorDocumentsRevisions(anchor.id);
        task.document = anchor.anchorDocument;
        task.phaseName = phase.customName;
        task.collaboratorId = projectTask.collaboratorId;
        task.userRole = projectTask.taskRole;
        task.dueDate = step.schedule.endDate;
        task.description = projectTask.description;
        task.status = projectTask.taskStatus;
        task.documentLocked = projectTask.documentLocked; // See DOS-2183.
        task.taskOverlaySigner = projectTask.taskOverlaySigner;
        task.signingBehalfOrganizationEnabled = projectTask.signingBehalfOrganizationEnabled;

        switch (task.status) {
          case Tasks.STATUS.COMPLETED:
            task.isInProgress = false;
            task.isComplete = true;
            break;
          case Tasks.STATUS.AVAILABLE: // Note: fall-through.
          case Tasks.STATUS.IN_PROGRESS:
            task.isInProgress = true;
            task.isComplete = false;
            break;
          default:
            task.isInProgress = false;
            task.isComplete = false;
          }
        // Copy 'task' object by assigning it into new object before
        // pushing it into the array.
        tasks.push(Object.assign({}, task));
      });
      return tasks;
    },

    /** Get project teams.
     *
     */

    teams:(state)=>{
      return state.project.teams;
    },

    /**
     * Prepare the Tasks data related to the actual user.
     *
     * How to use:
     * this.$store.getters.projectUsersTasks
     */
    projectUsersTasks: (state,getters) => {
      let collaboratorId = getters.getCollaboratorId;
      return getters.projectTasks.filter(task => {
        return task.collaboratorId === collaboratorId;
      });
    },

    /**
     * Gets the currently active Step in given Anchor-line (if any).
     *
     * How to use:
     * this.$store.getters.getActiveStepForAnchor(anchorId)
     */
    getActiveStepForAnchor: (state,getters) => anchorId => {
      let anchor = getters.getAnchorByID(anchorId);

      let activeStep = anchor.steps.find(step => step.stepStatus === Steps.STATUS.ACTIVE);
      return activeStep;
    },

    /**
     * Get one Phase by its ID from Store.
     *
     * How to use:
     * this.$store.getters.getPhaseByID(phaseId)
     */
    getPhaseByID: (state) => phaseId => {
      return state.project.phases.find(phase => phase.id === phaseId);
    },

    /**
     * Gets the internal index of the Phase.
     *
     * How to use:
     * this.$store.getters.getPhaseIndexByID(phaseId)
     */
     getPhaseIndexByID: (state) => phaseId => {
      // Note: This implementation expects that the Phases are already ordered by their natural
      //       Workflow order.

      return state.project.phases.findIndex(phase => phase.id === phaseId);
    },

    /**
     * Get one Step by its state ID from Store.
     *
     * How to use:
     * this.$store.getters.getStepByScheduleID(scheduleId)
     */
    getStepByScheduleID: (state) => scheduleId => {
      let step;

      state.project.anchors.forEach(anchor => {
        let stepInAnchor = anchor.steps.find(step => step.schedule.id === scheduleId);
        if (stepInAnchor) {
          step = stepInAnchor;
        }
      });

      return step;
    },

    /**
     * Get one Step by its ID from Store.
     *
     * How to use:
     * this.$store.getters.getStepByID(stepId)
     */
    getStepByID: (state) => stepId => {
      let step;

      state.project.anchors.forEach((anchor) => {
        let stepInAnchor = anchor.steps.find(step => step.id === stepId);
        if (stepInAnchor) {
          step = stepInAnchor;
        }
      });

      return step;
    },

    /**
     * Gets Step's internal index by step identity.
     *
     * How to use:
     * this.$store.getters.getStepIndexByID(stepId)
     */
     getStepIndexByID: (state) => stepId => {
      let index = -1;

      // Note: This expects that the Steps inside Anchor are ordered by their natural Workflow
      //       order.

      state.project.anchors.some((anchor) => {
        let stepIndex = anchor.steps.findIndex(step => step.id === stepId);
        if(stepIndex >= 0) {
          index = stepIndex;
          return true;
        }
        return false;
      });

      return index;
    },

    /**
     * Gets one Task by its ID from Store.
     *
     * Do note that this returns a task representation, not the original task object. Because
     * of that modifications done to the task object may not be persistent or reactive. If you need
     * to update a Task please use getTaskByIdForUpdate instead.
     *
     * How to use:
     * this.$store.getters.getTaskById(taskId)
     */
    getTaskById: (state,getters) => taskId => {
      // Do note that "getters.projectTasks" does not return the original "task" but
      // a representation of it. See the "projectTasks" documentation and implementation. Because
      // of that the task objects returned by this getter should not be modified.

      let task = getters.projectTasks.find(task => task.id === taskId);
      return task;
    },

    /**
     * Gets one Task by its identity from Store.
     *
     * Do note that this function (unlike getTaskById) returns a task that may be modified.
     *
     * How to use:
     * this.$store.getters.getTaskByIdForUpdate(taskId)
     */
    getTaskByIdForUpdate: (state) => taskId => {
      // 1. Get the view representation of the task.
      // Note: Do note that "projectTasks" getter does not return the original Tasks and they can't
      //       be modified/updated because of that. But they do contain the "stepId" that we need.
      // TODO: This could probably be optimized. Now we effectively fetch the Task from State twice.
      let task;
      if(state.project.tasks)
        task = state.project.tasks.find(task => task.id === taskId);
      return task;
    },

    /**
     * Get step tasks.
     */
    getStepTasks: (state) => stepId => {

      if(state.project.tasks)
        return state.project.tasks.filter(task => task.stepId ===stepId);

      return [];
    },

    /**
     * Get collaborator tasks.
     */
    getCollaboratorTasks: (state) => collaboratorId => {

      if(state.project.tasks)
         return state.project.tasks.filter(task => task.collaboratorId === collaboratorId);

      return [];
    },

    /**
     * Gets phase steps.
     *
     * To avoid data duplication step-data is only contained in the anchors.
     */

    getPhaseSteps:(state) =>phaseId =>{
      let phaseSteps = [];
       state.project.anchors.forEach(anchor => {
         anchor.steps.forEach(step =>{
           if(step.phaseRef === phaseId)
             phaseSteps.push(step);
         });
       });
       return phaseSteps;
    },

    getTeamById: (state) => teamId => {
      return state.project.teams.find(team => team.id === teamId);
    },

    /**
     * Get current logged-in user's Team information (if any)
     *
     * How to use:
     * this.$store.getters.getUserTeam
     */
    getUserTeam : (state, getters) => {
      let collaboratorId = getters.getCollaboratorId;
      let teams = state.project.teams;

      if(teams)
        return teams.find(team => team.collaborators.some(collaborator => collaborator.id == collaboratorId));
      else
        return null;
    },

    /** Get project workflow anchors.
     *
     */

    getAnchors: (state) => {
      return state.project.anchors;
    },

    /**
     * Get one Anchor by its ID from Store.
     *
     * How to use:
     * this.$store.getters.getAnchorByID(anchorId)
     */
    getAnchorByID: (state) => anchorId => {
      return state.project.anchors.find(anchor => anchor.id === anchorId);
    },

    /**
     * Gets Anchor index by it's identity.
     *
     * How to use:
     * this.$store.getters.getAnchorIndexByID(anchorId)
     */
     getAnchorIndexByID: (state) => anchorId => {
      // Note: This expects that the Anchors are already sorted by their Workflow natural order.

      return state.project.anchors.findIndex(anchor => anchor.id === anchorId);
    },

    /**
     * Get revisions of all documents that were attached to anchor by
     * anchor identity.
     *
     * How to use:
     * this.$store.getters.getAnchorDocumentsRevisions(anchorId)
     *
     */
    getAnchorDocumentsRevisions: (state, getters) => anchorId => {
      const anchor = getters.getAnchorByID(anchorId);

      // Get documents with unique identity to avoid duplication
      // if a document has been attached more than once
      const documentsHistory = [...new Set(anchor.anchorHistory.map(doc => doc.documentId))];
      let anchorRevisions = [];
      // Copy document revisions of each document to the anchor revisions array
      documentsHistory.forEach(docId => {
        const document = getters.getDocumentByID(docId);
        if(document)
          anchorRevisions.push.apply(anchorRevisions, document.revisions);
      });

      // Return revisions in sorted order
      return anchorRevisions.sort((a, b) => {
        // DOS-1406: Parse dates in Safari-compatible way.
        let a2 = Moment(a.modificationDate,'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate();
        let b2 = Moment(b.modificationDate,'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate();
        return a2-b2;
      });
    },

    /**
     * Get list of project Documents from Store.
     *
     * How to use:
     * this.$store.getters.getProjectDocuments()
     */
    getProjectDocuments: (state) => {
      return state.project.projectDocuments;
    },

    /**
     * Get one Document from Store.
     *
     * How to use:
     * this.$store.getters.getDocumentByID(documentId)
     */
    getDocumentByID: (state) => documentId => {
      if(state.project.projectDocuments)
        return state.project.projectDocuments.find(document => document.id === documentId);

      return null;
    },

    /**
     * Search for any possible Previous and Next Step
     *
     * This function is needed when Adding a Step
     * in an Anchor that did not have Steps (empty).
     * Since the DOM element of Steps are not linked together,
     * search is needed across Phases.
     *
     * Possible Previous and Next Steps are located
     * in different Phases.
     *
     * - Check for every Phase
     * - Check for every Steps in the Anchor that are included in each Phase
     * - If the Step is located prior the current Phase, then it is Previous Step
     * - If the Step is located after the current Phase, then it is Next Step
     *
     * How to use:
     * this.$store.getters.getPrevNextStep(anchorId, currentPhaseId)
     */
    getPrevNextStep: (state) => (anchorId, currentPhaseId) => {
      let phases = state.project.phases;
      let anchor = state.project.anchors.find((anchor) => {
        return anchor.id == anchorId;
      });
      let phase;

      let prevStepId;
      let nextStepId;
      let isAfterCurrentPhase = false;
      let isFinish = false;

      for (let i = 0; i < phases.length && !isFinish; i++) {
        phase = phases[i];
        if (phase.id == currentPhaseId) {
          isAfterCurrentPhase = true;
        }

        for (let j = 0; j < anchor.steps.length && !isFinish && phase.id != currentPhaseId; j++) {
          if (anchor.steps[j].phaseRef == phase.id) {
            if (!isAfterCurrentPhase) {
              prevStepId = anchor.steps[j].id;
            } else {
              nextStepId = anchor.steps[j].id;
              isFinish = true;
            }
          }
        }

      }

      return {
        previous: prevStepId,
        next: nextStepId
      }

    },

    /**
     * Searches for the next step neighbor.
     *
     * How to use:
     * this.$store.getters.getNextStepId(anchorId,currentStepId)
     */
    getNextStep: (state) => (anchorId, currentStepId) => {

      let anchor = state.project.anchors.find((anchor) => {
        return anchor.id == anchorId;
      });

      let isAfterCurrentStep = false;

      for (let i = 0; i < anchor.steps.length; i++) {
        if (isAfterCurrentStep) {
          return anchor.steps[i];
        }
        else if (anchor.steps[i].id === currentStepId) {
          isAfterCurrentStep = true;
        }
      }
    },

    /**
     * Returns the project admins in the current project.
     *
     * How to use:
     * this.$store.state.project.getProjectAdmins
     */
    getProjectAdmins: (state) => {
      let collaborators = state.project.collaborators;

      return collaborators != null ?
             collaborators.filter(collaborator => collaborator.projectRole === "PROJECT_ADMIN") :
             [];
    },

    /**
     * Returns the collaborators in the current project.
     *
     * How to use:
     * this.$store.getters.getProjectCollaborators
     */
    getProjectCollaborators: (state) => {
      return state.project.collaborators != null ?
             state.project.collaborators :
             [];
    },

    /**
     * Get extrenal collaborators for the current project.
     * DOS-849: filter collaborators by organization role
     */

    getProjectExternalCollaborators: (state)=> {
      let collaborators = state.project.collaborators;

      return collaborators != null ?
              collaborators.filter(collaborator =>
                ((!collaborator.organizationUserRole ||
                collaborator.organizationUserRole === 'GUEST') &&
                collaborator.projectRole != 'PROJECT_OWNER' &&
                collaborator.projectRole != 'INACTIVE' &&
                collaborator.collaboratorType != Collaborators.TYPES.BOT)) :
              [];
    },

    /**
     * Get internal collaborators of the current project.
     *
     */

    getProjectInternalCollaborators: (state)=> {
      let collaborators = state.project.collaborators;

      return collaborators != null ?
              collaborators.filter(collaborator =>
                (collaborator.organizationUserRole &&
                 collaborator.organizationUserRole != 'GUEST' &&
                 collaborator.projectRole != 'PROJECT_OWNER' &&
                 collaborator.projectRole != 'INACTIVE')) :
              [];
    },

    /**
     * Returns the project creator in the current project.
     *
     * Returned object:
     * {
     *   userId: String,
     *   fullName: String
     * }
     *
     * How to use:
     * this.$store.getters.getProjectCreator
     */
    getProjectCreator: (state) => {
      return state.project.projectCreator;
    },

    /**
     * Returns the project owners in the current project.
     *
     * How to use:
     * this.$store.state.project.getProjectOwners
     */
    getProjectOwners: (state) => {
      let collaborators = state.project.collaborators != null ?
                          state.project.collaborators :
                          [];

      return collaborators
        .filter(collaborator => collaborator.projectRole === "PROJECT_OWNER");
    },

    /**
     * Returns project tags.
     *
     * How to use:
     * this.$store.getters.getProjectTags
     */
    getProjectTags: (state) => {
      return state.project.tags != null ?
             state.project.tags :
             [];
    },

    /**
     * Gets the incomplete tasks from a step.
     *
     * How to use:
     * this.$store.getters.getIncompleteTasks(stepId)
     */
    getIncompleteTasks: (state,getters) => stepId => {
      let step = getters.getStepByID(stepId);

      let incompleteTasks = [];

      if(step != null) {
        for(let task of getters.getStepTasks(step.id)) {
          if(task.taskStatus == Tasks.STATUS.AVAILABLE ||
             task.taskStatus == Tasks.STATUS.IN_PROGRESS ||
             task.taskStatus == Tasks.STATUS.PAUSED) {
            incompleteTasks.push(task);
          }
        }
      }

      return incompleteTasks;
    },

    /**
     * Gets project rejected tasks.
     *
     * How to use:
     * this.$store.getters.getRejectedTasks
     */
     getRejectedTasks: (state,getters) => {
      if(state.project.tasks)
         return getters.projectTasks.filter(task => task.status == Tasks.STATUS.REJECTED);

      return [];
    },

    /**
     * Gets Collaborator object for Collaborator identity.
     *
     * How to use:
     * this.$store.getters.getCollaboratorById(collaboratorId)
     */
    getCollaboratorById: (state) => collaboratorId => {
      return state.project.collaborators != null ?
             state.project.collaborators.find(collaborator => collaborator.id === collaboratorId) :
             null;
    },

    /**
     * Gets the current logged in user's Collaborator identity (if any).
     *
     * How to use:
     * this.$store.getters.getCollaboratorId
     */
    getCollaboratorId: (state,getters,rootState,rootGetters) => {
      let userId = getters.getUserId;

      if(userId == 'guest') {
        let collaboratorId = rootGetters.getGuestCollaboratorId
        if(!collaboratorId)
          console.error("Guest collaborator id missing");
        return collaboratorId;
      } else {
        let collaborator = getters.getCollaboratorByUserId(userId);

        return collaborator != null ? collaborator.id : null;
      }
    },

     /**
     * Gets Collaborator by email.
     *
     * How to use:
     * this.$store.getters.getCollaboratorByUserEmail(email)
     */
    getCollaboratorByUserEmail: (state) => email => {
      return state.project.collaborators != null ?
             state.project.collaborators.find(collaborator => collaborator.email === email):
             null;
    },

    /**
     * Gets Collaborator object for User identity.
     *
     * How to use:
     * this.$store.getters.getCollaboratorByUserId(userId)
     */
    getCollaboratorByUserId: (state) => userId => {
      return state.project.collaborators != null ?
             state.project.collaborators.find(collaborator => collaborator.userId === userId) :
             null;
    },

    /**
     * Search for user project role based on user Id
     *
     * How to use:
     * this.$store.state.project.getUserProjectRoleByUser(userId)
     */
    getUserProjectRoleByUser: (state, getters, rootState, rootGetters) => userId => {
      let collaborator;
      if(userId == 'guest') {
        let collaboratorId = rootGetters.getGuestCollaboratorId;
        if(!collaboratorId)
          console.error("Guest collaborator id missing");
        else
          collaborator = getters.getCollaboratorById(collaboratorId);
      } else {
        collaborator =
          state.project.collaborators != null ?
          state.project.collaborators.find(collaborator => collaborator.userId === userId) :
          null;
      }
      return collaborator ? collaborator.projectRole : null;
    },

    getProjectId: (state) => {
      return state.project.id;
    },

    /**
     * Gets the count of unread notification failures in the Project.
     *
     * How to use:
     * this.$store.getters.getProjectUnreadNotificationFailureCount
     */
    getProjectUnreadNotificationFailureCount: (state) => {
      return state.project.unreadNotificationFailureCount != null ?
             state.project.unreadNotificationFailureCount :
             0;
    },

    /**
     * Gets whether the step allows step completion.
     *
     * @param stepId step identity
     *
     * @return whether the step allows step completion
     *
    */
    isStepCompletionAllowed: (state,getters) => stepId => {
      let step = getters.getStepByID(stepId);
      if(!step)
        return false;

      return step.allowStepCompletion?true:false;
    },

    /**
     * Get list of project groups.
     *
     * How to use:
     * this.$store.getters.getProjectGroups
     */
    getProjectGroups: (state) => {
      return state.project.assignedGroups;
    },

    /**
     * Get name of the project.
     *
     * How to use:
     * this.$store.getters.getProjectName
     */
    getProjectName: (state) => {
      return state.project.name;
    },

    /**
     * Get project state from store.
     *
     * How to use:
     * this.$store.getters.getProject
     */
     getProject: (state) => {
      return state.project;
    },

    /**
     * Get status of the project.
     */
     getProjectStatus: (state) => {
      return state.project.projectStatus;
    },

    /**
     * Get identified user data.
     *
     * How to use:
     * this.$store.getters.getIdentifiedUser
     */
    getIdentifiedUser: (state) => {
      return state.identifiedUser;
    },

    /**
     * Get Advanced team feature value.
     *
     * @see https://jira.intra.documill.com/browse/DOS-1850
     *
     * How to use:
     * this.$store.getters.getEditTeamsEnabled
     */
    getEditTeamsEnabled: (state) => {
      return state.project.editTeamsEnabled;
    },

    getProjectOrganizationName: (state) => {
      return state.project.organization.name;
    },

    /**
     * Gets the count of unread event failures in the Project.
     */
    getProjectUnreadEventFailureCount: (state) => {
      return state.project.unreadEventFailureCount != null
             ? state.project.unreadEventFailureCount
             : 0;
    },

  },


  //
  //
  // End: Getters
  //
  //

  //
  //
  // Start: Mutations
  //
  //

  mutations: {


    /** Adds an anchor to the store state.
     *
     *  For params structure
     *  @see com.documill.platform.flow.controller.api.model.AnchorApiResponse
     *
     * @param {*} state
     * @param {*} params
     */

    addAnchor(state, params) {
      if(!state.project.anchors)
        state.project.anchors = [];

      state.project.anchors.push(params);
    },

    /**
     * Adds a new Document to state.
     *
     * Expected parameters:
     *
     * params = {
     *   "id": string,
     *   "fileName": string,
     *   "fileLocation" : string,
     *   "projectId" : string,
     *   "uploaderId": string,
     *   "creationDate": string,
     *   "modificationDate": string,
     * }
     */
    addDocument(state, params) {
      let index =
        state.project.projectDocuments ? state.project.projectDocuments.length : 0;

      state.project.projectDocuments[index] = params;
    },

    /**
     * Deletes a Project Document from the state.
     *
     * @param {String} documentId document identity
     */
    deleteDocument(state, documentId) {
      state.project.projectDocuments =
          state.project.projectDocuments.filter(document => document.id != documentId);
    },

    addTask(state, params) {
      let task = {
        "taskStatus" : params.taskStatus,
        "description" : params.description,
        "stepId" : params.stepId,
        "taskRole" : params.taskRole,
        "collaboratorId" : params.collaboratorId,
      };

      state.project.tasks.push(task);
    },

    /**
     * Updates a single task description in the Project state.
     *
     * Expected parameters:
     * params = {
     *   id: String,
     *   description: String
     * }
     */

    updateTaskDescription(state, params) {
      let task = this.getters.getTaskById(params.id);

      task["description"] = params.description;
    },

    /**
     * Updates a single Task "document locked" property in the Project state.
     *
     * If a Task has been "locked" it means only a certain Document can be
     * uploaded to the Task as a new Revision. This is used for Offline
     * Edit Steps. See DOS-1887.
     *
     * Expected parameters:
     * params = {
     *   id: String,
     *   documentLocked: Boolean
     * }
     */

    updateTaskDocumentLocked(state, params) {
      let task = this.getters.getTaskByIdForUpdate(params.id);

      task["documentLocked"] = params.documentLocked;
    },

    /**
     * Updates a single task status in the Project state.
     *
     * Expected parameters:
     * params = {
     *   taskId: String,
     *   taskStatus: String
     * }
     */

    updateTaskStatus(state, params) {
      let task = this.getters.getTaskByIdForUpdate(params.taskId);

      // FIXME: Why use "status" when the API response field is named "taskStatus"? Consider
      //        setting only the "taskStatus" field. Currently it sets both.

      task["status"] = params.taskStatus;
      task["taskStatus"] = params.taskStatus;
    },

    /**
     * Adds a new collaborator to state.
     *
     * Notice that this does not create a new collaborator if a matching
     * collaborator with status "INACTIVE" already exists. Instead we just
     * change that collaborator's project role.
     *
     * Expected parameters:
     *
     * params = {
     *   "projectId": string,
     *   "userId": string,
     *   "fullName" : string,
     *   "projectRole": string,
     *   "email": string
     * }
     * @param {*} state state
     * @param {*} params parameters object
     */

    addCollaborator(state, params) {
      // First check if there is already a "INACTIVE" collaborator with the matching
      // identity (user, or email) and update the role of that collaborator instead
      // of adding a new one.
      let collaborator = null;

      if(params.userId)
        collaborator = this.getters.getCollaboratorByUserId(params.userId);
      else if(params.email)
        collaborator = this.getters.getCollaboratorByUserEmail(params.email);

      if(collaborator != null) {
        if(collaborator.projectRole == "INACTIVE")
          this.commit("updateCollaborator", params);
        // else: do nothing, the collaborator already exists in state.
      }
      else {

        // Note: Some of the information is null because we simply do not know
        //       that. The data will be refreshed when the collaborator is
        //       added finally to DB. The most notable field that might be missing
        //       is the (collaborator) "id" (link between user and project).

        let stateParams = {
          "tasks": [],
          "id": params.id,
          "fullName": params.fullName,
          "userId": params.userId,
          "projectRole": params.projectRole,
          "defaultTaskRole": params.defaultTaskRole,
          "email": params.email,
          "teamId": params.teamId,
        }

        state.project.collaborators = [...state.project.collaborators, stateParams];
      }
    },

    /** Adds a collaborator to a team.
     *
     * @param {*} state
     * @param {*} params
     */

    addTeamCollaborator(state,params){

      let team = this.getters.getTeamById(params.teamId);
      if(!team.collaborators)
        team.collaborators = [];

      team.collaborators = [...team.collaborators, params];
    },

    /**
     * Adds a new tag to state.
     *
     * Expected parameters:
     *
     * params = {
     *   "id": string,
     *   "label": string
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */
    addTag(state, params) {

      state.project.tags.push(params);
    },

    /**
     * Adds a new team to state.
     *
     * @param {*} state state
     * @param {*} params parameters object
     */
    addTeam(state, params) {

      state.project.teams.push(params);
    },

    /**
     * Updates team information.
     *
     * Note: The "targetId" and "id" are separate because this mutator allows converting
     *       "placeholder" teams to real teams by setting correct identity to the placeholder
     *       team.
     *
     * Expected parameters:
     * {
     *   "targetId": String (target Team identity we are updating),
     *   "id": String,
     *   "projectId": String,
     *   "name": String,
     *   "external": Boolean,
     *   "notReady": String (signals that Team creation is not yet ready)
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */
    updateTeam(state, params) {
      state.project.teams = state.project.teams.map(team => {
        if(team.id === params.targetId) {
          return params;
        }
        return team;
      })
    },

    /**
     * Attaches a new extra file to a step.
     *
     * Expected parameters:
     *
     * params = {
     *   "stepId": string,
     *   "documentId": string,
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */

    setStepExtraDocuments(state, params) {
      let stepId = params.id;

      let step = this.getters.getStepByID(stepId);

      if(!step) {
        logger.error("Could not find step for step id:",
                            stepId);
        return;
      }

      step["extraDocuments"] = params.extraDocuments;
    },

    /**
     * Removes an extra file from a step.
     *
     * Expected parameters:
     *
     * params = {
     *   "stepId": string,
     *   "documentId": string,
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */

    removeExtraFileFromStep(state, params) {
      let step = this.getters.getStepByID(params.stepId);
      let document = this.getters.getDocumentByID(params.documentId);

      if(!step) {
        logger.error("Could not find step for step id:",
                              params.stepId);
        return;
      }

      if(!document) {
        logger.error("Could not find document for document id:",
                              params.documentId);
        return;
      }

      let index = step.extraDocuments.findIndex(extraDocument => extraDocument.documentId == document.id);

      if(index == -1) {
        logger.error("Could not find extra document",document.id,
                            " to be deleted in step:",step.id);
        return;
      }

      step.extraDocuments.splice(index,1);
    },

    /**
     * Attaches a new document to anchor.
     *
     * Expected parameters:
     *
     * params = {
     *   "anchorId": string,
     *   "documentId": string,
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */

    attachDocument(state, params) {
      let anchor = this.getters.getAnchorByID(params.anchorId);

      if(anchor != null) {
        let document = this.getters.getDocumentByID(params.documentId);

        if(document != null)
          anchor["anchorDocument"] = document;
        else
          logger.error("Could not find document for id:",params.documentId);
      }
      else {
        logger.error("Could not find anchor for anchor id:",params.anchorId);
      }
    },

    /**
     * Adds the step to the store state.
     * The neighbouring steps hold a reference to
     * the step in field "next"/"previous" depending on the order.
     *
     * Notice that this might not fully update the state. This is currently used
     * to have a quick response from the UI to step addition as well as
     * provide the step reference to the store state to enable assingment
     * addition to the step.
     *
     * @param {*} state
     * @param {*} params
     */

    addStep(state,params) {

      let anchor = this.getters.getAnchorByID(params.stepAnchor);
      let previousStep = this.getters.getStepByID(params.previous);
      let nextStep = this.getters.getStepByID(params.next);

      // Update the neighbouring step next/pref references
      if(previousStep)
        previousStep['next'] = params.id;
      if(nextStep)
        nextStep['previous'] = params.id;

      // Update the anchor:

      // If previous step is not defined, it must be the first step;
      let anchorStepIdx = 0 ;

      if(!anchor.steps) // Initialize anchor.steps array. Should not happen.
        anchor["steps"] = [];

      for(let idx = 0; idx < anchor.steps.length; idx++){
        if(anchor.steps[idx].id == params.previous){
          anchorStepIdx = idx+1;
          break;
        }
      }

      let anchorSteps = [...anchor.steps];

      anchorSteps.splice(anchorStepIdx,0, params);
      anchor["steps"] = anchorSteps;
    },

    /**
     * Moves step.
     *
     * Moves the step by remove - add actions.
     *
     * params: {
     *   id : step identity
     *   newAnchorRef: new anchor identity
     *   newPhaseRef: new phase identity
     *   newPrevRef: new previus step identity
     *   newNextRef: nex next step identity
     * }
     *
     * @param {*} state
     * @param {*} params
     */

    moveStep(state,params) {
      let stepId = params.id;
      let step = this.getters.getStepByID(stepId);
      let deleteTasks = false;

      this.commit("deleteStep",{stepId,deleteTasks});

      // Update the step data.
      step.stepAnchor = params.newAnchorRef;
      step.phaseRef = params.newPhaseRef;
      step.previous = params.newPrevRef;
      step.next = params.newNextRef;

      this.commit("addStep",step);
    },

    /**
     * Deletes a step from the store state.
     *
     * Removes the assingments related to that step from the store state.
     * Removes the references to the previous and next steps.
     * Updates the reference in the owning anchor and phase.
     *
     * params:
     *  - stepId: identity of the removed step
     *  - deleteTasks: whether to delete tasks also
     *
     * @param {*} state
     * @params {*} params
     *
     */

     deleteStep(state, params) {

      let deleteTasks = params.deleteTasks;
      let stepId = params.stepId;
      let store = this;
      let step = this.getters.getStepByID(stepId);

      if(deleteTasks){
        this.getters.getStepTasks(step.id).forEach(function (task) {
          let params = {
            "stepId": stepId,
            "taskId": task.id,
          };
          store.commit("deleteTask",params);
        });
      }

      let anchor = this.getters.getAnchorByID(step.stepAnchor);
      let previousStep = this.getters.getStepByID(step.previous);
      let nextStep = this.getters.getStepByID(step.next);

       if (previousStep)
          previousStep['next'] = step.next;
       if (nextStep)
          nextStep['previous'] = step.previous;

       // If previous step is not defined, it must be the first step;
       let anchorStepIdx = anchor.steps.findIndex(step => step.id===stepId);
       let anchorSteps = [...anchor.steps]

       anchorSteps.splice(anchorStepIdx, 1);
       anchor["steps"] = anchorSteps;
     },

     /**
      * Delete a team from the store state.
      *
      * @param {*} state
      * @param {*} teamId
      */

     deleteTeam(state,teamId) {
      let team = this.getters.getTeamById(teamId);

      // Delete all the team collaborators from the project and the team.
      let store = this;

      if(team.collaborators != null) {
        team.collaborators.forEach(function(collaborator) {
          store.commit("deleteTeamCollaborator",{collaboratorId:collaborator.id,
                                                      teamId:teamId});
          store.commit("deleteCollaborator",collaborator.id);
        });
      }

      state.project.teams =
          state.project.teams.filter(team => team.id !== teamId);
     },

     /**
      * Set team name.
      *
      * @param {*} state
      * @param {*} params
      */

     setTeamName(state,params) {
       let team = this.getters.getTeamById(params.teamId);
       team['name'] = params.name;
     },

    /**
     * Updates the steps properties to state.
     *
     * Expected parameters:
     *
     * updateStepRequest = {
     *   id: String (step identity),
     *   attachFileInNotification: Boolean (optional),
     *   allowStepCompletion: Boolean (optional),
     *   signerOverrideAllowed: Boolean (optional),
     *   requireSignerIdentityVerification: Boolean (optional),
     *   combinedFileName: String (optional),
     * }
     *
     * @param {*} state
     * @param {*} updateStepRequest update step request
     */
    updateStepProperties(state,updateStepRequest) {
      let step = this.getters.getStepByID(updateStepRequest.id);

      if(updateStepRequest.attachFileInNotification != null)
        step['attachFileInNotification'] = updateStepRequest.attachFileInNotification;

      if(updateStepRequest.allowStepCompletion != null)
        step['allowStepCompletion'] = updateStepRequest.allowStepCompletion;

      if(updateStepRequest.signerOverrideAllowed != null)
        step['signerOverrideAllowed'] = updateStepRequest.signerOverrideAllowed;

      if(updateStepRequest.requireSignerIdentityVerification != null)
        step['requireSignerIdentityVerification'] = updateStepRequest.requireSignerIdentityVerification;

      if(updateStepRequest.allowAccessToOriginalDocument != null)
        step['allowAccessToOriginalDocument'] = updateStepRequest.allowAccessToOriginalDocument;

      if(updateStepRequest.combinedFileName != null)
        step['combinedFileName'] = updateStepRequest.combinedFileName;
    },

    /**
     * Adds a phase to the store state.
     *
     * Notice that this does not fix the step order if the phase contains steps.
     *
     * @param {*} state
     * @param {*} phase ( phase api response object )
     */

    addPhase(state,phase) {

      let prev = this.getters.getPhaseByID(phase.previous);
      let next = this.getters.getPhaseByID(phase.next);
      let phases = state.project.phases;

      if(prev)
        prev['next'] = phase.id;
      if(next)
        next['previous'] = phase.id;

      let prevePhaseIndx = 0;

      for(let idx = 0; idx < phases.length; idx++){
        if(phases[idx].id == phase.previous){
            prevePhaseIndx = idx+1;
            break;
        }
      }

      let newPhases = [...phases];
      newPhases.splice(prevePhaseIndx,0,phase);
      state.project["phases"] = newPhases;
    },

    /**
     * Deletes a phase.
     *
     * @param {*} state
     * @param {*} params
     *
     * params:
     *   phaseId : phase identity
     *   deleteSteps : whether to delete steps inside the phase
     *   deleteTasks : whether to delete tasks inside the phase
     *
     */

    deletePhase(state, params) {

      let phase = this.getters.getPhaseByID(params.phaseId);
      let next = this.getters.getPhaseByID(phase.next);
      let prev = this.getters.getPhaseByID(phase.previous);
      let deleteTasks = params.deleteTasks;
      let store = this;

      if(params.deleteSteps)
        this.getters.getPhaseSteps(params.phaseId).forEach(function(step) {
          let stepId = step.id;
          store.commit("deleteStep",{stepId, deleteTasks});
        });

      if(prev)
        prev["next"] = next?next.id:null;

      if(next)
         next["previous"] = prev?prev.id:null;

      state.project.phases =
          state.project.phases.filter(phase => phase.id != params.phaseId);
    },

    deleteTask(state, params) {
      state.project.tasks =
          state.project.tasks.filter(task => task.id !== params.taskId);
    },

    deleteCollaborator(state, collaboratorId) {
      let collaborators = this.getters.getProjectCollaborators;

      let collaborator = collaborators.find(c => c.id === collaboratorId);
      collaborator["projectRole"] = "INACTIVE";
      collaborator["team"] = null;

    },

    deleteTeamCollaborator(state, params) {

      let team = this.getters.getTeamById(params.teamId);
      team.collaborators =
          team.collaborators.filter(c => c.id !== params.collaboratorId);
    },

    /**
     * Deletes a tag from project state.
     *
     * @param {*} state state
     * @param {String} tagId identity of the tag to be deleted
     */
    deleteTag(state, tagId) {
      state.project.tags =
          state.project.tags.filter(tag => tag.id !== tagId);
    },

    /**
     * Updates a tag label to state.
     *
     * Expected parameters:
     * {
     *   "tagId": string,
     *   "label": string
     * }
     *
     * @param {*} state state
     * @param {*} params parameters
     */
    updateTagLabel(state, params) {
      let tags = this.getters.getProjectTags;

      let tag = tags.find(c => c.id === params.tagId);

      if(tag != null)
        tag["label"] = params.label;
    },

    detachDocument(state, anchorId) {
      let anchor = this.getters.getAnchorByID(anchorId);

      if(anchor != null) {
        // Back-end sets the "anchorDocument" to null in responses. We mimic this also here and do
        // not remove the whole field.
        // Note: See issue DOS-1575.

        // Vue.delete(anchor,"anchorDocument");

        anchor["anchorDocument"] = null;
      }
      else {
        logger.error("Could not find anchor for anchor id:",anchorId);
      }
    },

    /**
     * Resets the Workflow of the current Project. This essentially means that
     * all Anchors and Phases of the Project are deleted.
     *
     * Expected parameters:
     * - none
     *
     * @param state state
     */
    resetWorkflow(state) {
      let project = state.project;

      project["phases"] = [];
      project["anchors"] = [];
    },

    /**
     * Writing Project data to Store
     *
     * This is used when loading Project page
     * and when updating the data.
     */
    setProject(state, project) {

      // Reset the project task status filters when the project changes.
      if(state.project==null || state.project.id != project.id)
        this.commit("ui/RESET_TASK_STATUS_FILTERS");

      state.project = project;
    },

    /**
     * Update Step schedule in Project store
     * @param {*} state
     * @param {*} newSchedule
     */
    setStepSchedule(state, newSchedule) {
      let step = this.getters.getStepByScheduleID(newSchedule.id);
      if (step)
        step["schedule"] = newSchedule;
    },

    /**
     * Writing supporting data to Store
     */
    setCollaboratorRoles(state, newRoles) {
      state.collaboratorRoles = newRoles;
    },

    /**
     * Updates selected collaborators role to store state.
     *
     * Expected parameters:
     *
     * params = {
     *   "id": String,
     *   "projectRole": String
     * }
     */

    updateCollaboratorRole(state,collaborator) {
      for(let c of state.project.collaborators) {
        if(c.id == collaborator.collaboratorId) {
          c.projectRole = collaborator.projectRole;
          c["projectRole"] = collaborator.projectRole;
        }
      }
    },

    /**
     * Updates selected team collaborators role to store state.
     *
     * Expected parameters:
     *
     * params = {
     *   "collaboratorId": String,
     *   "projectRole": String
     *   "teamId":"string
     * }
     */

    updateTeamCollaboratorRole(state,params) {
      let team = this.getters.getTeamById(params.teamId);

      for(let c of team.collaborators) {
         if(c.id == params.collaboratorId)
            c.projectRole = params.projectRole;
      }
    },

    /**
     * Updates the new project state to store state.
     */

    updateProjectPlannedSchedule(state,newSchedule){
      state.project = {...state.project, plannedSchedule: newSchedule};
    },

    /**
     * Empty the Project data in Store
     *
     * This is used when closing Project page.
     *
     */
    resetProject(state) {
      delete state["project"];
      delete state["projectNameDuplicate"];
      state.project = {};
      state.projectNameDuplicate = {};

      // TODO: Are collaborator roles "project" specific things? Should they be
      //       at e.g. organization level?

      state.collaboratorRoles = {};
    },

    /**
     * Updates task (e.g. role).
     *
     * Expected parameters:
     *
     * params = {
     *  "taskRole": "string",
     *  "stepId": "string",
     *  "id": "string",
     *  "taskStatus": "string",
     *  "collaboratorId": "string"
     *  "description": "string"
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */

    updateTask(state, params) {
      // FIXME: There's both params.taskId and params.id.
      //        The params.taskId is apparently used to query the store,
      //        potentially matching undefined == undefined below.
      //        The params.id is used to update the found task.
      //        See also DOS-2172.
      let task = state.project.tasks.find(task => task.id === params.taskId);

      if(task != null) {
        if(params.taskRole != null)
          task["taskRole"] = params.taskRole;
        if(params.id != null)
          task["id"] = params.id;
        if(params.taskStatus != null)
          task["status"] = params.taskStatus;
        if(params.status != null)
          task["status"] = params.status;
        if(params.collaboratorId != null)
          task["collaboratorId"] = params.collaboratorId;
        if(params.description != null)
          task["description"] = params.description;
        if(params.documentLocked != null)
          task["documentLocked"] = params.documentLocked;
        if(params.signingBehalfOrganizationEnabled != null)
          task["signingBehalfOrganizationEnabled"] = params.signingBehalfOrganizationEnabled;

        // TODO: Potentially other fields.
      }
    },

    /**
     * Updates a single collaborator. Tries to find a Collaborator entity that
     * has the same user than the parameters.
     *
     * Expected parameters:
     *
     * params = {
     *  "id": "string",
     *  "stepId": "string",
     *  "fullName": "string",
     *  "userId": "string",
     *  "projectRole": "string",
     *  "email": "string"
 		 *	"teamId": "string",
     * }
     *
     * @param {*} state
     * @param {*} params
     */

    updateCollaborator(state, params) {

      let collaborators = this.getters.getProjectCollaborators;

      // DOS-814:
      // Firstly, search new collaborator by collaborator ID.
      // If none, search new collaborator by userId if he/she is in the same organization
      // or by email if he/she is in different org or not registered
      let collaborator;

      if(params.id != null)
        collaborator = collaborators.find(c => c.id === params.id);
      if(!collaborator && params.userId != null)
        collaborator = collaborators.find(c => c.userId === params.userId);
      if(!collaborator && params.email != "Email unavailable")
        collaborator = collaborators.find(c => c.email === params.email);


      if(collaborator != null) {
        if(params.id != null)
          collaborator["id"] = params.id;
        if(params.fullName != null)
          collaborator["fullName"] = params.fullName;
        if(params.projectRole != null)
          collaborator["projectRole"] = params.projectRole;
        if(params.defaultTaskRole != null)
          collaborator["defaultTaskRole"] = params.defaultTaskRole;
        if(params.email != null)
          collaborator["email"] = params.email;
        if(params.id != null)
          collaborator["id"] = params.id;
        if(params.teamId != null)
          collaborator["teamId"] = params.teamId;
        if(params.signerDetails != null)
          collaborator["signerDetails"] = params.signerDetails;
      }
    },

    /**
     * Updates a single collaborator's signer details.
     *
     * Expected parameters:
     *
     * params = {
     *   "id": "string" (collaborator id),
     *   "signerDetails": {
     *     "email": "string",
     *     "fullName": "string",
     *     "title": "string",
     *     "organization": "string",
     *     "definesVisualSignature": "boolean",
     *     "signatureTemplateId": "string"
     *   }
     * }
     *
     * @param {*} state
     * @param {*} params
     */

    updateCollaboratorSignerDetails(state, params) {
      let collaborators = this.getters.getProjectCollaborators;
      let collaborator = collaborators.find(collaborator => collaborator.id === params.id);

      collaborator["signerDetails"] = params.signerDetails;
    },

    /**
     * Updates a task signingBehalfOrganizationEnabled value.
     *
     * Expected parameters:
     *
     * params = {
     *   "collaboratorId": "string",
     *   "taskId": "string",
     *   "signingBehalfOrganizationEnabled": "boolean"
     * }
     *
     * @param {*} state
     * @param {*} params
     */
    updateTaskSigningBehalfOrganizationEnabled(state, params) {
      let task = state.project.tasks.find(task => task.id === params.taskId);

      task["signingBehalfOrganizationEnabled"] = params.signingBehalfOrganizationEnabled;
    },

    /**
     * Updates a single collaborator in a team. Tries to find a Collaborator entity that
     * has the same user than the parameters.
     *
     * Expected parameters:
     *
     * params = {
     *  "id": "string",
     *  "stepId": "string",
     *  "fullName": "string",
     *  "userId": "string",
     *  "projectRole": "string",
     *  "email": "string"
 		 *	"teamId": "string",
     * }
     *
     * @param {*} state
     * @param {*} params
     */

    updateTeamCollaborator(state,params) {

      let team = this.getters.getTeamById(params.teamId);
      let collaborator  = team.collaborators.find(c => c.id === params.id);

      if(collaborator != null) {
        if(params.collaboratorId != null)
          collaborator["collaboratorId"] = params.collaboratorId;
        if(params.fullName != null)
          collaborator["fullName"] = params.fullName;
        if(params.projectRole != null)
          collaborator["projectRole"] = params.projectRole;
        if(params.defaultTaskRole != null)
          collaborator["defaultTaskRole"] = params.defaultTaskRole;
        if(params.email != null)
          collaborator["email"] = params.email;
        if(params.teamId != null)
          collaborator["teamId"] = params.teamId;
        }
    },

    /**
     * Updates the project description.
     * @param {*} state
     * @param {String} description new project description
     */
    updateProjectDescription(state,description) {
      state.project["description"] = description;
    },

    /**
     * Updates the project name.
     *
     * @param {*} state
     * @param {String} name new project name
     */
    updateProjectName(state,name) {
      state.project["name"] = name;
    },

    /**
     * Updates the project name duplicate status.
     *
     * @param {*} state
     * @param {Object} nameDuplicate whether project's name is duplicate
     */
    updateProjectNameDuplicate(state,nameDuplicate) {
      state["projectNameDuplicate"] = nameDuplicate;
    },

    /**
     * Updates a project setting.
     *
     * @param {*} state
     * @param {*} setting object with "key" and "value" fields
     */
    updateProjectSetting(state,setting) {
      state.project[setting.key] = setting.value;
    },

   /**
    * Sets identified user data.
    *
    * @param {*} state
    * @param {*} params object with "key" and "value" fields
    */
    setIdentifiedUser(state, params) {
      state.identifiedUser = params;
    },

    /**
     * Adds a project to a group.
     *
     * Expected parameters:
     *
     * params = {
     *   "projectId": string,
     *   "group": {
     *      id: string,
     *      name: string
     *   }
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */
    ADD_PROJECT_TO_GROUP(state, params) {
      if(state.project.assignedGroups == null)
        state.project["assignedGroups"] = [];

      state.project.assignedGroups.push(params.group);
    },

    /**
     * Deletes a project from a group.
     *
     * Expected parameters:
     *
     * params = {
     *   "projectId": string,
     *   "groupId": string
     * }
     *
     * @param {*} state state
     * @param {*} params parameters object
     */
    DELETE_PROJECT_FROM_GROUP(state, params) {
      state.project.assignedGroups =
          state.project.assignedGroups.filter(group => group.id != params.groupId);
    },

    SET_PROJECT_STATUS(state,status) {
      state.project["projectStatus"] = status;
    },

    /**
     * Sets the unread notification failure count to state.
     *
     * @param {Number} count
     */
    SET_UNREAD_NOTIFICATION_FAILURE_COUNT(state,count) {
      state.project["unreadNotificationFailureCount"] = count;
    },

    /**
     * Sets the unread event failure count to state.
     *
     * @param {Number} count
     */
    SET_UNREAD_EVENT_FAILURE_COUNT(state,count) {
      state.project["unreadEventFailureCount"] = count;
    },
  },

  //
  //
  // End: Mutations
  //
  //

  //
  //
  // Start: Actions
  //
  //

  actions: {

    /**
     * Get Project data from API and then put the data in Store.
     *
     * The action returns Promise so that the component that use it can decide
     * what to do if the API call fails.
     *
     * Note: If you simply wish to reload the current project data see
     *       'scheduleProjectReload' action instead as it is "debounced".
     *
     * How to use:
     * this.$store.dispatch("getProject", projectId);
     *
     * @param {string} projectId project to reload (may be null/undefined, in
     *                           that case reloads the current project)
     */
    async getProject(context, projectId) {

      // TODO: Consider changing the name to "reloadProjectData" or similar.

      // If no projectId is given, we consider this a "reload" request.

      if(!projectId)
        projectId = this.state.project.project.id;
      if(!projectId)
        throw "Can not call query getProject, projectId is not defined.";

      try {

        // Pass the project modification date in "Modification-Date" custom header, DOS-1746.
        let hasLastModified = this.state.project.project && this.state.project.project.modificationDate
        let config = {};

        if(hasLastModified){
           config = {
            headers: {
              'Modification-Date': this.state.project.project.modificationDate,
            }
          }
        }

        let url = 'v1/projects/' + projectId;

        const result = await apiCall.get(url,config);

        // If the "projectReloadCanceled" flag is on, ignore this project
        // update.

        if(this.scheduledProjectReloadCanceled !== true) {
          const project = result.data;
          logger.debug("Query getProject succeeded with result:",
                              project);
          context.commit("setProject", project);

          // Check if project's name is duplicate
          this.dispatch("isProjectNameDuplicate");
        }
        this.scheduledProjectReloadCanceled = false;

        return result;
      }
      catch(error) {

        if(error.response.status == "304"){
          logger.debug("Query getProject didn't return data since the project data is up to date.")
        }
        else{
          logger.error("Query getProject didn't succeed with message:", error);
          throw error;
        }
      }
    },

    /**
     * Schedules a current project reload. Reloads the current project data
     * from backend API and refreshes the data in Store with it.
     *
     * Do note that this should be used only in cases where the UI has already
     * been updated and we just wish to fetch the authoritative version from
     * backend. Also note that this function is "debounced" so first call
     * within the timeframe schedules the update and subsequent calls to this
     * function will simply be ignored until the update is finally actually
     * fired.
     *
     * Because of the debouncing this action is not usable if the state needs to
     * be updated ASAP. Main idea with this action is to perform the project
     * reload after all the UI changes have been done and if new UI changes
     * are done the project reload is delayed further until no new UI changes
     * are encountered. This will fix issues where the UI is updated multiple
     * times and then authoritative update from backend overwrites the UI
     * changes.
     *
     * @param {*} context
     */

    scheduleProjectReload: function(context) {
      if(!this.scheduledProjectReload) {
        this.scheduledProjectReload = debounce(function(context) {
          // Set the "isCanceled" flag false. If some event cancels the project
          // reload between this point and when we actually reload the
          // project data, discard the project data.

          this.scheduledProjectReloadCanceled = false;

          // Note: "debounce" comes from Lodash.
          // The reloads to current project are debounced. Reason for this is that
          // if multiple requests are encountered we do not need to fulfil them
          // all.
          // Note: If user has logged out then do not try to load project data, it will result a fail.
          //       This may happen if a project reload is scheduled and user logs out before the
          //       reload is fired.

          if(context.getters.isAuthenticated)
            context.dispatch("getProject");
        },4000);
      }

      this.scheduledProjectReload(context); // Execute.
    },

    /**
     * Set Project data into Store
     */
    setProject(context, newProject) {
      context.dispatch("saveProject", newProject);
      context.commit("setProject", newProject);
    },

    /**
     * Set Project description and update the Project
     *
     * How to use:
     * this.$store.dispatch("updateProjectDescription", description)
     *
     * @param {String} description
     */
    async updateProjectDescription(context, description) {
      let projectId = context.state.project.id;
      let oldDescription = context.state.project.description;

      if(description == oldDescription)
        return; // Bail out, no need to update.

      let params = {
        id: projectId,
        description: description
      };

      context.commit("updateProjectDescription", description);

      try {
        const result = await apiCall.put('v1/projects/update/description', params);
        logger.debug("PUT updateProjectDescription succeeded with result:", result);

        return result;
      }
      catch(error) {
        logger.error("PUT updateProjectDescription didn't succeed with message:", error);

        // Note: Revert back to previous description.
        context.commit("updateProjectDescription", oldDescription);
        throw error;
      }
    },

    /**
     * Updates Project name.
     *
     * How to use:
     * this.$store.dispatch("updateProjectName", name)
     *
     * @param {String} name new name for the project
     */
    async updateProjectName(context, name) {
      let projectId = context.state.project.id;
      let oldName = context.state.project.name;

      if(oldName == name)
        return; // Bail out, no need to update.

      let params = {
        id: projectId,
        name: name
      };

      context.commit("updateProjectName", name);

      try {
        const result = await apiCall.put('v1/projects/update/name', params);
        logger.debug("PUT updateProjectName succeeded with result:", result);

        // Also check for duplicate name
        this.dispatch("isProjectNameDuplicate");

        return result;
      }
      catch(error) {
        logger.error("PUT updateProjectName didn't succeed with message:", error);

        // Note: Revert back to previous name.
        context.commit("updateProjectName", oldName);
        throw error;
      }
    },

    /**
     * Updates Project name duplicateness.
     *
     * How to use:
     * this.$store.dispatch("isProjectNameDuplicate")
     */
    async isProjectNameDuplicate(context) {
      let projectId = context.state.project.id;

      let params = {
      };

      try {
        const result = await apiCall.get(`v1/projects/${projectId}/is-project-name-duplicate`, params);
        logger.debug("GET isProjectNameDuplicate succeeded with result:", result);

        context.commit("updateProjectNameDuplicate", result.data);

        return result;
      }
      catch(error) {
        logger.error("GET isProjectNameDuplicate didn't succeed with message:", error);

        throw error;
      }
    },

    /**
     * Updates a Project Setting.
     *
     * How to use:
     * this.$store.dispatch("updateProjectSetting", {'key': settingName,'value': settingValue})
     * or
     * this.$store.dispatch("updateProjectSetting", {'key': settingName,'value': settingValue}, projectId)
     * projectId is needed when we are in Projects page
     */
    async updateProjectSetting(context, {key,value,projectId}) {

      let params = {
        id: projectId ? projectId : context.state.project.id
      };

      params[key] = value;

      context.commit("updateProjectSetting", {'key': key, 'value': value});

      try {
        const result = await apiCall.put('v1/projects/update/settings', params);
        logger.debug("PUT updateProjectSettings succeeded with result:", result);

        return result;
      }
      catch(error) {
        logger.error("PUT updateProjectSettings didn't succeed with message:", error);

        // Note: Get Project data again from back-end.
        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Create a new project and sets it as the current project for State.
     */
    async createProject(context, isTemplate) {

      let params = {
        template: isTemplate,
        name: Projects.getDefaultName(isTemplate)
      };

      try {
        const result = await apiCall.post('v1/projects/new', params);
        logger.debug("POST createProject succeeded with result:", result);
        let projectData = result.data;
        projectData.isNew = true;

        context.commit("setProject", result.data);

        // Check if project's name is duplicate
        await this.dispatch("isProjectNameDuplicate");

        return result.data.id;
      }
      catch(error) {
        logger.error("POST createProject didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Applying a workflow from Model Project into Project
     */
    async cloneProjectWorkflow(context, params) {

      let apiParams = {
        projectRef: context.state.project.id,
        templateRef: params.templateId,
        documentNameConflictStrategy: params.conflictResolveStrategy,
      };

      try {
        const result = await apiCall.put('v1/projects/clone', apiParams)
        logger.debug("cloneProject succeeded with result:", result);
        context.commit("setProject", result.data);
        return result;
      }
      catch(error) {
        logger.error("cloneProject didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Applying a workflow from Model Project into Project
     */
    async createProjectFromTemplate(context, params) {

      let apiParams = {
        templateRef: params.templateId,
        documentNameConflictStrategy: params.conflictResolveStrategy,
      };

      try {
        const result = await apiCall.put('v1/projects/createProjectFromTemplate', apiParams)
        logger.debug("createProjectFromTemplate succeeded with result:", result);
        return context.dispatch("getProject", result.data.id);
      }
      catch(error) {
        logger.error("createProjectFromTemplate didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Copies Project to a new Project.
     *
     * Expected params: {
     *   projectName: String,
     *   projectId: String
     * }
     */
    async copyProject(context, params) {

      let apiParams = {
        name: Projects.getDefaultCopyName(params.projectName),
        projectId: params.projectId
      };

      try {
        const result = await apiCall.put('v1/projects/copy',apiParams);
        logger.debug("copyProject succeeded with result:", result);
        context.commit("setProject", result.data);
        context.dispatch("getProject"); // Forced update right away.

        // Check if project's name is duplicate
        this.dispatch("isProjectNameDuplicate");

        return result;
      }
      catch(error) {
        logger.error("copyProject didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Save a copy of the project as Workflow template
     *
     * @param {*} context context
     * @param {*} projectId identity of the copied project
     *
     * @returns {Promise} promise
     */
    async saveProjectAsTemplate(context, projectId) {

      try {
        const result = await apiCall.put('/v1/projects/saveAsTemplate/'+ projectId)
        logger.debug("Save Project as workflow template succeeded with result:", result);
        return context.dispatch("getProject", result.data.id);
      }
      catch(error) {
        logger.error("Save Project as workflow template didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Add a Collaborator into Project.
     *
     * Notice that both the back end implementation and the store state mutators
     * behave similarly in case when a collaborator is added, but a matching
     * INACTIVE one already exists. In these case the inactive collaborator's
     * project role is changed from INACTIVE to something else.
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   userId: "String", (optional either userId or email must given)
     *   fullName: "String",
     *   email: "String", (optional either userId or email must given)
     *   projectId: "String" (optional, if null will use current project id),
     *   projectRole: "String" (optional, if null will use "COLLABORATOR")
     * };
     *
     * @param {*} context context
     * @param {*} params parameters object
     *
     * @returns {Promise} promise
     */

    async addCollaborator(context, params) {

      let projectId = params.projectId != null ?
                      params.projectId :
                      context.state.project.id;

      let projectRole = params.projectRole != null ?
                        params.projectRole :
                        "COLLABORATOR";

      let stateParams = {
        "id": null, // Note: We do not yet know the Collaborator's identity.
        "projectId": projectId,
        "projectRole": projectRole,
        "userId": params.userId,
        "fullName": params.fullName,
        "email": params.email
      }

      // Commit mutation right away to state so end-user will see the visual
      // change right away (i.e. UI will feel responsive).

      this.commit("addCollaborator", stateParams);


      // Note: We wish to reload project state especially if adding collaborator
      //       fails so the actual valid state is reflected in UI.

      if (params.userId) {

        let apiParams = {
          "projectId": projectId,
          "projectRole": projectRole,
          "userId": params.userId
        }

        try {
          const result = await apiCall.put('v1/users/project/link', apiParams)
          logger.debug("addCollaborator succeeded with result:",
                              result);

          // Update the state with the latest collaborator information. This
          // will set e.g. the "collaboratorId".

          context.commit("updateCollaborator", result.data);

          // TODO: Consider enabling the following to fetch the authoritative
          //       state:
          // "context.dispatch("scheduleProjectReload");"

          return result;
        }
        catch(error) {
          logger.error("addCollaborator didn't succeed with message:",
                              error);

          context.dispatch("getProject"); // Forced update right away.
          throw error;
        }
      }
      else { // User added by email, invited.

        let apiParams = {
          "projectId": projectId,
          "email": params.email
        }

        try {
          const result = await apiCall.put('v1/invitation/external', apiParams)
          logger.debug("addCollaborator succeeded with result:",
                              result);

          // Update the state with the latest collaborator information. This
          // will set e.g. the "collaboratorId".

          context.commit("updateCollaborator", result.data);

          // TODO: Consider enabling the following to fetch the authoritative
          //       state:
          // "context.dispatch("scheduleProjectReload");"

          return result;
        }
        catch(error) {
          logger.error("addCollaborator didn't succeed with message:",
                              error);

          context.dispatch("getProject"); // Forced update right away.
          throw error;
        }

      }
    },

    /** Creates a team and adds the given user as a team leader.
     *
     * Notice that the team leader should be specified in params either
     * by email or user identity
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   teamLeaderUserId: "String", (optional, either teamLeaderUserId or teamLeaderEmail)
     *   teamLeaderEmail: "String", (optional, either teamLeaderUserId or teamLeaderEmail)
     *   projectId: "String"
     *   external: "boolean",
     *   name: "String",
     * };
     *
     * @param {*} context
     * @param {*} params
     */

    async addTeam(context, params) {

      let teamLeader = {
        projectId:params.projectId,
        userId:params.teamLeaderUserId,
        email: params.teamLeaderEmail,
        projectRole: "TEAM_LEADER",
      }

      // Create a placeholder Team with a pseudo identity. This is just to visualize the
      // Team in UI as fast as possible.
      // Note: This will be updated when the actual team is created.

      let placeholderTeam = {
        projectId: params.projectId,
        name: params.name,
        id: "placeholder-team", // Pseudo identity.
        notReady: true, // Not yet ready.
        external: params.external
      };

      context.commit("addTeam",placeholderTeam);

      // Create a team first.

      try {
        const result = await apiCall.post("v1/teams/new", params)

        logger.debug("Team creation succeeded with result:",result);

        // Team creation succeeded. Update our placeholder team.

        let teamData = result.data;
        teamData.targetId = "placeholder-team"; // Our target identity.
        teamData.notReady = true;
        context.commit("updateTeam",teamData);

        teamLeader.teamId = result.data.id;  // Set the team identity:

        if(teamLeader.userId)
          await context.dispatch("addTeamCollaborator",teamLeader);
        else
          await context.dispatch("inviteTeamCollaborator",teamLeader);

        // Mark the Team as "ready". This signals the UI to show the Team.

        teamData.notReady = false;
        context.commit("updateTeam",teamData);

        return result;
      }
      catch(error) {
        logger.error("Team creation  failed with message:",error);
        context.dispatch("getProject"); // Forced update right away.

        throw error;
      }
    },

    /** Adds a team collaborator to a team.
     *
     * Adds an existing user as collaborator.
     *
     * @param {*} context
     * @param {*} params
     */


    async addTeamCollaborator(context,params){

      try {
        const result = await apiCall.post("v1/teams/collaborator", params)
        logger.debug("Team collaborator creation succeeded with result:",result);
        // Update the state with the latest collaborator information.
        context.commit("addTeamCollaborator", result.data);
        context.commit("addCollaborator", result.data);
        return result;
      }
      catch(error) {
        logger.error("Team collaborator creation failed with message:",error);

        context.dispatch("getProject"); // Forced update right away.
        throw error;
      }
    },

    /** Invite a team collaborators to a team.
     *
     * @param {*} context
     * @param {*} params
     */


    async inviteTeamCollaborator(context,params){

      try {
        const result = await apiCall.put("v1/invitation/external", params)
        logger.debug("Team collaborator creation succeeded with result:",result);
        // Update the state with the latest collaborator information.
        context.commit("addTeamCollaborator", result.data);
        context.commit("addCollaborator", result.data);
        return result;
      }
      catch(error) {
        logger.error("Team collaborator creation failed with message:",error);

        context.dispatch("getProject"); // Forced update right away.
        throw error;
      }
    },

    /**
     * Set Team name in database and update the store state
     *
      * Expected parameters are as follows:
     *
     * let params = {
     *   teamId: String,
     *   name: String,
     * };
     *
     * @param {*} params parameters object
     */
    async setTeamName(context, params) {

      context.commit("setTeamName", params);

      try {
        const result = await apiCall.post("v1/teams/update", params)
        logger.debug("Team Update succeeded with result:",result);
        return result;
      }
      catch(error) {
        logger.error("Team Update  failed with message:",error);

        context.dispatch("getProject"); // Forced update right away.

        throw error;
      }

    },

    /**
     * Assign Team to a Phase
     *
     * let params = {
     *   phaseId: String,
     *   teamId: String,
     * };
     *
     * @param {*} params parameters object
     */
    async assignTeamToPhase(context, params) {

      try {
        const result = await apiCall.post("v1/teams/assign", params)
        context.dispatch("getProject"); // Forced update right away.
        logger.debug("Assign team to phase succeeded with result:",result);
        return result;
      }
      catch(error) {
        logger.error("Assign team to phase failed with message:",error);

        context.dispatch("getProject"); // Forced update right away.

        throw error;
      }

    },

    /** Deletes a team by Id.
     *
     * @param {*} context
     * @param {*} teamId
     */

    async deleteTeam(context, teamId){

      context.commit("deleteTeam", teamId);

      let url = "v1/teams/"+teamId;

      try {
        const result = await apiCall.delete(url)

        logger.debug("Delete Team succeeded with result:",result);
        context.dispatch("getProject"); // Forced update right away.
        return result;
      }
      catch(error) {
        logger.error("Delete Team failed with message:",error);
        context.dispatch("getProject"); // Forced update right away.
        throw error;
      }
    },

    /**
     * Adds a Tag into Project.
     *
     * If the Tag already exists it will be just linked to Project. If the
     * Tag does not exist it will be first created.
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   id: "String", (may be null, implies the tag does not exist)
     *   label: "String",
     *   projectRef: "String"
     * };
     *
     * How to use:
     * this.$store.dispatch("addTag", params);
     *
     * @param {*} context context
     * @param {*} params parameters object
     *
     * @returns {Promise} promise
     */
    async addTag(context, params) {

      // Add the tag to state right away (with a temporary identity if needed).
      // Note: Tag identity can be available if the tag has been added earlier
      //       to organization. If this is the first time ever the tag has been
      //       added to a project there will be no identity.

      let idMissing = params.id == null;
      let stateTemporaryId = !idMissing ? params.id : "temporary-tag-id";

      let stateParams = {
        id : stateTemporaryId,
        label : params.label,
        projectRef : params.projectRef
      };

      context.commit("addTag",stateParams);

      try {
        const result = await apiCall.post("v1/tags/add/", params)

        // Note: If identity is missing, remove the fake item and add the
        //       official tag with the correct identity.

        if(idMissing) {
          context.commit("deleteTag",stateTemporaryId);
          context.commit("addTag",result.data);
        }

        logger.debug("addTag succeeded with result:",result);
        return result;
      }
      catch(error) {
        logger.error("addTag failed with message:",error);

        context.dispatch("getProject"); // Forced update right away.
        throw error;
      }
    },

    /**
     * Deletes a Tag from Project.
     *
     * Do note that while the Tag is removed from Project the Tag itself is
     * not deleted. Only the link between Tag and Project is deleted (except if that link was the
     * only/last one).
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   tagRef: "String",
     *   projectRef: "String"
     * };
     *
     * How to use:
     * this.$store.dispatch("deleteTag", params);
     *
     * @param {*} context context
     * @param {*} params parameters object
     *
     * @returns {Promise} promise
     */
    async deleteTag(context, params) {

      context.commit("deleteTag", params.tagRef);

      try {
        const result = await apiCall.post("v1/tags/delete/", params)
        logger.debug("deleteTag succeeded with result:",result);

        return result;
      }
      catch(error) {
        logger.error("deleteTag failed with message:",error);

        context.dispatch("getProject"); // Forced update right away.

        throw error;
      }
    },

    /**
     * Updates a Tag label.
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   projectId: "String",
     *   tagId: "String",
     *   label: "String"
     * };
     *
     * How to use:
     * this.$store.dispatch("updateTagLabel", params);
     *
     * @param {*} context context
     * @param {*} params parameters object
     *
     * @returns {Promise} promise
     */
    async updateTagLabel(context, params) {

      context.commit("updateTagLabel", params);

      try {
        const result = await apiCall.patch("v1/tags/label/", params)
        logger.debug("updateTagLabel succeeded with result:",result);

        return result;
      }
      catch(error) {
        logger.error("updateTagLabel failed with message:",error);

        throw error;
      }
    },

    /**
     * Sends an invitation to an existing internal Collaborator. This is mainly
     * used for informing Collaborators that they should start working with the
     * Project.
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   collaboratorId: "String"
     * };
     *
     * @param {*} context context
     * @param {*} params parameters object
     *
     * @returns {Promise} promise
     */

    async inviteInternalCollaborator(context, params) {

      let apiParams = {
        "collaboratorId": params.collaboratorId
      }

      try {
        const result = await apiCall.put('v1/invitation/internal', apiParams)
        logger.debug("inviteCollaborator succeeded with result:",
                            result);

        return result;
      }
      catch(error) {
        logger.error("inviteCollaborator failed with message:",
                            error);

        throw error;
      }
    },

    /**
     * Update a collaborator's role in a project.
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   collaboratorId: "String",
     *   projectRole: "String"
     * };
     */
    updateCollaboratorRole(context, params) {

      // Update the UI optimistically according to the selection.

      context.commit("updateCollaboratorRole", params);

      apiCall.patch('v1/users/projectRole/update', params)
        .then(response => {
          logger.debug("Collaborator role change succeeded with result:",
                              response);

          // TODO: Consider enabling the following to fetch the authoritative
          //       state:
          // "context.dispatch("scheduleProjectReload");"
        })
        .catch(error => {
          logger.error("Collaborator role change didn't succeed:",
                              error);

          // Reload the project data, as the UI is most likely in wrong state.
          context.dispatch("getProject");
        });
    },

    /**
     * Update a team collaborator's role
     *
     * Notice that this method only changes the role of the collaborator in
     * the team, the project may hold a copy of the collaborator also.
     *
     * Expected parameters are as follows:
     *
     * let params = {
     *   collaboratorId: "String",
     *   projectRole: "String",
     *   teamId: "String"
     * };
     */

    updateTeamCollaboratorRole(context, params){

      // Update the team store data both in project and team.
      context.commit("updateTeamCollaboratorRole", params);
      context.commit("updateCollaboratorRole", params);

      // We can use the same endpoint here as for normal collaborators.
      apiCall.patch('v1/teams/collaborator/', params)
      .then(response => {
        logger.debug("Collaborator role change succeeded with result:",
                            response);

        // TODO: Consider enabling the following to fetch the authoritative
        //       state:
        // "context.dispatch("scheduleProjectReload");"
      })
      .catch(error => {
        logger.error("Collaborator role change didn't succeed:",
                            error);

        // Reload the project data, as the UI is most likely in wrong state.
        context.dispatch("getProject");
      });

    },

    /**
     * Saving Project data to database through API
     *
     * Should be executed before putting any changes into State.Project
     * Only when saving to database is done, we can update the State.Project
     * (Above is not implemented yet though)
     *
     */
    saveProject(context, project) {
      let projectToBeSaved = {};

      projectToBeSaved.id = project.id;
      projectToBeSaved.name = project.name;
      projectToBeSaved.description = project.description;
      projectToBeSaved.duration = project.duration;
      projectToBeSaved.projectStatus = project.projectStatus;
      projectToBeSaved.inviteExternalCollabs = project.inviteExtCollab;
      projectToBeSaved.collaborateBackground = project.collaborateBackground;
      projectToBeSaved.collaborateWorkspace = project.collaborateWorkspace;
      projectToBeSaved.artifactStorageEnabled = project.artifactStorageEnabled;
      projectToBeSaved.deliverSignedDocumentsEnabled = project.deliverSignedDocumentsEnabled;
      projectToBeSaved.assignedGroups = project.assignedGroups;
      projectToBeSaved.editTeamsEnabled = project.editTeamsEnabled;
      projectToBeSaved.quickStartProjectEnabled = project.quickStartProjectEnabled;
      projectToBeSaved.simplerUxEnabled  = project.simplerUxEnabled;

      apiCall.put('/v1/projects/update', projectToBeSaved)
        .then(result => {
          logger.debug("Project data is saved");
        })
        .catch(error => {
          logger.error("Project data is not saved");
          logger.error(error.response);
        })
    },

    /**
     * Sends the target Project's files to final storage(s). Final storages are optional
     * Organization-level storage integrations that can be configured via the Organization
     * configuration page.
     *
     * Do note that this works only for completed Projects.
     *
     * See issue DOS-1148.
     */
    async sendProjectFilesToStorage(context,projectId) {
      let action = "sendProjectFilesToStorage";

      context.commit("ui/ADD_BUSY_ITEM",action);

      try {
        const result = await apiCall.get('/v1/storages/store/' + projectId)
        logger.debug("Project files have been stored to final storages.");
        context.commit("ui/REMOVE_BUSY_ITEM",action);
        return result;
      }
      catch(error) {
        logger.error("Sending Project files to storage failed.");
        logger.error(error.response);
        context.commit("ui/REMOVE_BUSY_ITEM",action);
        throw error;
      }
    },

    /**
     * Set project to CANCELED.
     */
    async setProjectCanceled(context, projectId) {
      let uiItem = "setProjectCanceled";

      context.commit("ui/ADD_BUSY_ITEM",uiItem);

      try {
        const result = await apiCall.put('/v1/projects/' + projectId + '/cancel')
        logger.debug("Project has been canceled.");
        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);
        return result;
      }
      catch(error) {
        logger.error("Canceling Project failed.");
        logger.error(error.response);

        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);
        throw error;
      }
    },

    /**
     * Set project to go live.
     *
     * Do note that this can mean both setting project go from PLANNING state
     * to LIVE or from PAUSED state to LIVE.
     */
    async setProjectLive(context, projectId) {
      let busyItemName = "setProjectLive";

      context.commit("ui/ADD_BUSY_ITEM",busyItemName);

      try {
        const result = await apiCall.put('/v1/projects/' + projectId + '/live')
        logger.debug("Project has been set to Live status");
        // reload project data from database
        context.commit("ui/REMOVE_BUSY_ITEM",busyItemName);

        context.commit("SET_PROJECT_STATUS",Projects.STATUS.LIVE);

        await context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("Setting project to Live status failed.");
        logger.error(error.response);
        // reload project data from database
        context.dispatch("getProject");
        context.commit("ui/REMOVE_BUSY_ITEM",busyItemName);
        throw error;

      }
    },

    /**
     * Set Project to go to paused state.
     */
    async setProjectPaused(context, projectId) {
      let uiItem = "setProjectPaused";

      context.commit("ui/ADD_BUSY_ITEM",uiItem);

      try {
        const result = await apiCall.put('/v1/projects/' + projectId + '/pause')
        logger.debug("Project has been Paused.");
        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);

        // Reload project data from database.

        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("Pausing project failed.");
        logger.error(error.response);
        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);

        // Reload project data from database.

        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Schedule the project.
     */
    async setProjectScheduled(context, projectId) {
      let busyItemName = "setProjectScheduled";

      context.commit("ui/ADD_BUSY_ITEM",busyItemName);

      try {
        const result = await apiCall.put('/v1/projects/' + projectId + '/schedule')
        logger.debug("Project has been set to Scheduled status");
        // reload project data from database
        context.commit("ui/REMOVE_BUSY_ITEM",busyItemName);

        context.commit("SET_PROJECT_STATUS",Projects.STATUS.SCHEDULED);

        await context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("Setting project to Scheduled status failed.");
        logger.error(error.response);
        // reload project data from database
        context.dispatch("getProject");
        context.commit("ui/REMOVE_BUSY_ITEM",busyItemName);
        throw error;
      }
    },

    /**
     * Set project back to Planning.
     */
    async setProjectPlanning(context, projectId) {
      let uiItem = "setProjectPlanning";

      context.commit("ui/ADD_BUSY_ITEM",uiItem);

      try {
        const result = await apiCall.put('/v1/projects/' + projectId + '/planning')
        logger.debug("Project was set back to Planning.");
        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);
        context.commit("SET_PROJECT_STATUS",Projects.STATUS.PLANNING);

        await context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("Set Project back to planning failed.");
        logger.error(error.response);
        // reload project data from database
        context.dispatch("getProject");
        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);
        throw error;
      }
    },

    /**
     * Delete project from database.
     */
    async deleteProject(context, projectId) {
      let uiItem = "deleteProject";

      context.commit("ui/ADD_BUSY_ITEM",uiItem);
      this.commit("removeFromProjectList",projectId);

      try {
        const result = await apiCall.delete('v1/projects/'+ projectId)
        logger.debug("DELETE project succeeded with result:", result);
        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_BUSY_ITEM",uiItem);
        logger.error("DELETE project didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Reset the Project data in Store.
     *
     * This is used when closing Project page and is needed so that there won't
     * be previous Project data visible when opening another Project.
     *
     * How to use:
     * this.$store.dispatch("resetProjectStore");
     */
    resetProjectStore(context) {
      // If there is a scheduled project reload: cancel it now because we do not
      // wish that it fires after we have reset the project data. After reset
      // there is not even a project identity which could be used for fetching
      // the data so the project data reload will cause an error.

      Commons.cancel(this.scheduledProjectReload);

      // Make sure this is 'false' because if it is left 'true' the next
      // 'getProject' action call will not update the state.

      this.scheduledProjectReloadCanceled = false;

      context.commit("resetProject");
    },

    /**
     * Reorder Phases
     * Expected parameter:
     * newOrder = {
     *   "id": movedPhaseId,
     *   "newNextRef": newNextRef,
     *   "newPrevRef": newPrevRef,
     *   "oldNextRef": oldNextRef,
     *   "oldPrevRef": oldPrevRef,
     * }
     *
     * How to use:
     * this.$store.dispatch("reorderPhase", newOrder);
     */
    async reorderPhase(context, newOrder) {

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.patch('/v1/phases/reorder', newOrder)
        logger.debug("updatePhaseOrder succeeded with result:",
                            result);
        // Commit the new state of the project to the store state.
        context.commit("setProject", result.data);

        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);

        return result;
      }
      catch(error) {

        //Reload the project data after failed reorder.
        context.dispatch("getProject");

        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("updatePhaseOrder didn't succeed with message:",
                            error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        throw error;
      }
    },

    /**
     * Add Phase data to database through API
     *
     * let params = {
     *  "customName": "custom name",
     *  "next": idOfNextPhase, // Optional
     *  "phaseStatus": "UPCOMING",
     *  "phaseType": "EDITING",
     *  "previous": idOfPrevPhase, // Optional
     *  "projectRef": project.id
     * }
     */
    async addPhase(context, params) {

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.post('v1/phases/new', params)
        logger.debug("addProjectPhase succeeded with result:",
                            result);
          // Update the store state with the new phase data.
          context.commit("addPhase", result.data);
        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
          return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("addProjectPhase didn't succeed with message:");
        logger.error(error.response);
        throw error;

      }
    },

    /**
     * Saving Phase data to database through API
     *
     */
    setPhase(context, phase) {
      let phaseToBeSaved = {};

      phaseToBeSaved.customName = phase.customName;
      phaseToBeSaved.id = phase.id;
      phaseToBeSaved.next = phase.next;
      phaseToBeSaved.phaseStatus = phase.phaseStatus;
      phaseToBeSaved.phaseType = phase.phaseType;
      phaseToBeSaved.previous = phase.previous;
      phaseToBeSaved.phaseState = phase.phaseState;
      phaseToBeSaved.syncTaskExit = phase.syncTaskExit;

      context.commit("ui/ADD_WORKFLOW_SAVING");

      return apiCall.put('/v1/phases/update', phaseToBeSaved)
        .then(result => {
          context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
          logger.debug("Phase data is saved");
        })
        .catch(error => {
          context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
          logger.error("Phase data is not saved");
          logger.error(error.response);
        })
    },

    /**
     *  Update step schedule (duration, start or end date)
     *
     * @param {*} context
     * @param {*} params data of a new schedule of a step
     */

    async updateStepSchedule(context, params) {
      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.put('v1/schedules/update', params)
        context.commit('setStepSchedule', params);
        logger.debug("Step schedule is updated");
        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
        return result;
      }
      catch(error) {
        logger.error("Step schedule is not updated", error);
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        throw error;

      }
    },

    /**
     * Add a Step data to database through API.
     *
     */

    async addStep(context, params) {

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.post('v1/steps/add', params)
        logger.debug("addStepToProject succeeded with result:", result);

        context.dispatch("getProject"); // Forced update right away.

        // Update the state right away as the response contains the
        // step identity, which is needed to for task creation.
        let stepParams = result.data;

        // Do note that if there are default tasks, the newly created Step may already contain
        // tasks.
        if(!stepParams.tasks)
          stepParams.tasks = [];

        context.commit("addStep",stepParams);

        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);

        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("addStepToProject didn't succeed with error:",error);
        logger.error(error.response);
        throw error;
      }
    },

    async completeStep(context, stepId) {
      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.patch("v1/steps/"+ stepId +"/complete")
        logger.debug("Step completion succeeded with result:", result);

        // Reload project data so the data has the latest Workflow data.
        // TODO: Loading project data seems to slow. Consider returning the ProjectApiResponse
        //       when completing a step to make the UI more reactive.
        //await context.dispatch("getProject");
        this.commit("setProject",result.data);
        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
        return;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("step completion didn't succeed with error:",error);
        logger.error(error.response);
        throw error;
      }
    },

    /**
     * Reorder Step
     *
     * Expected parameter:
     * newOrder = {
     *    "id": "string",
     *    "newAnchorRef": "string",
     *    "newNextRef": "string",
     *    "newPhaseRef": "string",
     *    "newPrevRef": "string",
     *    "oldAnchorRef": "string",
     *    "oldNextRef": "string",
     *    "oldPrevRef": "string",
     *  }
     *
     * How to use:
     * this.$store.dispatch("reorderStep", newOrder);
     */
    async reorderStep(context, newOrder) {

      context.commit("moveStep",newOrder);

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.patch('/v1/steps/reorder', newOrder)
        logger.debug("updateStepOrder succeeded with result:", result);

        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        context.dispatch("getProject");
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("updateStepOrder didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        throw error;
      }
    },

    /**
     * Updates step properties.
     *
     * Expected parameters:
     *
     * params = {
     *   "id": String (required),
     *   "allowStepCompletion": boolean (optional)
     * }
     *
     * @see com.documill.platform.flow.controller.api.model.UpdateStepPropertiesRequest
     *
     * @param {*} context
     * @param {*} stepUpdateRequest object containing the update information
     */

    async updateStepProperties(context,updateStepRequest) {

      // Update state.

      context.commit("updateStepProperties",updateStepRequest);

      // Update back-end.

      try {
        const result = await apiCall.patch('/v1/steps/update', updateStepRequest)
        logger.debug("updateStep succeeded with result:", result);
        return result;
      }
      catch(error) {
        logger.error("updateStep didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        // Reload project data so the data has the latest Workflow data
        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Resets the current Workflow by deleting it's components one by one:
     * Project Phases and Anchors.
     *
     * @param context
     *
     * @returns {Promise} promise
     */
    async resetWorkflow(context) {

      let projectId = context.state.project.id;

      // Commit the change right away so the UI will be updated. If the reset
      // eventually fails we refresh the state right back by doing a full
      // project state reload.

      context.commit("resetWorkflow");

      try {
        const result = await apiCall.put('/v1/projects/' + projectId + '/resetWorkflow')
        logger.debug("Project Workflow has been reset");

        // Reload project data.
        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("Project Workflow reset has failed:", error);

        // Reload project data.
        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Deletes a Collaborator from the Project.
     *
     * Expected parameters:
     *
     * params = {
     *   "collaboratorId": "String"
     * }
     */

    async deleteCollaborator(context, params) {

      // Commit the change right away so the UI will be updated. If the
      // deletion actually fails we refresh the state right back as soon as
      // possible.

      context.commit("deleteCollaborator", params.collaboratorId);

      try {
        const result = await apiCall.delete('/v1/users/collaborator/' + params.collaboratorId)
        logger.debug("Query deleteCollaborator succeeded:",result);

        // TODO: Consider enabling the following to fetch the authoritative
        //       state:
        // "context.dispatch("scheduleProjectReload");"

        return result;
      }
      catch(error) {
        // TODO: Is the code below needed?
        // UIkit.notification("Deleting collaborator failed potentially due to assigned tasks");

        logger.error("Query deleteCollaborator failed:",error);

        // Force a state refresh, our current state might be in mismatch.

        context.dispatch("getProject");

        throw error;
      }
    },


    /**
     * Deletes a Collaborator from a Team.
     *
     * Expected parameters:
     *
     * params = {
     *   "collaboratorId": "String"
     * }
     */

    async deleteTeamCollaborator(context, params) {

      // Commit the change right away so the UI will be updated. If the
      // deletion actually fails we refresh the state right back as soon as
      // possible.

      context.commit("deleteTeamCollaborator", params);
      context.commit("deleteCollaborator", params.collaboratorId);

      try {
        const result = await apiCall.delete('/v1/teams/collaborator/'+ params.collaboratorId)
        logger.debug("Delete collaborator succeeded:",result);

        // TODO: Consider enabling the following to fetch the authoritative
        //       state:
        // "context.dispatch("scheduleProjectReload");"

        return result;
      }
      catch(error) {
        // TODO: Is the code below needed?
        // UIkit.notification("Deleting collaborator failed potentially due to assigned tasks");

        logger.error("Delete collaborator failed:",error);

        // Force a state refresh, our current state might be in mismatch.

        context.dispatch("getProject");

        throw error;
      }
    },


    /**
     * Delete Step
     *
     * Expected parameter: stepId
     */
    async deleteStep(context, stepId) {

      let deleteTasks = true;

      this.commit("deleteStep",{stepId, deleteTasks});

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.delete('v1/steps/removal/' + stepId)
        logger.debug("deleteStep succeeded with result:", result);
        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        context.dispatch("getProject");
        logger.error("deleteStep didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Delete Phase
     *
     * Expected parameter: phaseId
     */
    async deletePhase(context, phaseId) {

      // Remove the phase from the store state.
      let deleteTasks = true;
      let deleteSteps = true;
      context.commit("deletePhase", {phaseId, deleteSteps, deleteTasks } );

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.delete('/v1/phases/' + phaseId)
        logger.debug("Query deletePhase succeeded with result:", result);
        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        context.dispatch("getProject");
        logger.error("Query deletePhase didn't succeed with message:", error);
        throw error;
      }
    },

    /**
     * Delete Anchor
     *
     * Expected parameter: anchorId
     */
    async deleteAnchor(context, anchorId) {
      try {
        const result = await apiCall.delete('/v1/anchors/' + anchorId)
        logger.debug("Query deleteAnchor succeeded with result:", result);
        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        // TODO: Is this needed? Probably should be moved to some other place.
        // UIkit.notification("Deleting anchor failed potentially due to having connected steps");
        logger.error("Query deleteAnchor didn't succeed with message:", error);
        throw error;

      }
    },

    /**
     * Delete File
     *
     * Expected parameter: fileId
     */
    async deleteFile(context, fileId) {

      try {
        const result = await apiCall.delete('/v1/documents/project/' + fileId)
          logger.debug("Query deleteFile succeeded with result:", result);
          context.dispatch("getProject");
          return result;
        }
        catch(error) {
          logger.error("Query deleteFile didn't succeed with message:", error);
          throw error;
      }
    },

    /**
     * Get the list of possible Roles for Collaborators in a Project
     *
     * Should be run at least once when opening a Project/Workflow.
     */
    loadCollaboratorRoles(context) {

      return new Promise((resolve) => {
        // FIXME: Maybe this should be named something more generic like
        //        "loadOrganizationData" and put the data under "organization"
        //        object.
        // FIXME: This probably should be changed to an API call that would
        //        retrieve the roles available/defined for the organization.
        //        It might be possible for different organizations to have
        //        set of roles and different default roles.
        // TODO: Maybe we should also load the set of supported step types/goals?

        // By default get an array containing all available role keys.

        let roles = {};

        roles[Steps.TYPES.APPROVE] = [
          Collaborators.ROLES.APPROVER,
        ];
        roles[Steps.TYPES.DELIVER] = [
          //Collaborators.ROLES.EXTERNAL_READER,
          Collaborators.ROLES.INTERNAL_READER
        ];
        roles[Steps.TYPES.EDIT] = [
          Collaborators.ROLES.APPROVING_EDITOR,
          Collaborators.ROLES.COMMENTER,
          Collaborators.ROLES.EDITOR
        ];
        roles[Steps.TYPES.OFFLINE_EDIT] = [
          Collaborators.ROLES.OFFLINE_EDITOR
        ];
        roles[Steps.TYPES.REVIEW] = [
          Collaborators.ROLES.REVIEWER
        ];
        roles[Steps.TYPES.SIGN] =[
          Collaborators.ROLES.SIGNER
        ];
        roles[Steps.TYPES.SIGN_CONFIGURE] = [
          Collaborators.ROLES.SIGNING_CONFIGURATOR,
        ];
        roles[Steps.TYPES.EXTERNAL_EDIT] = [
          Collaborators.ROLES.EXTERNAL_APPROVING_EDITOR,
          Collaborators.ROLES.EXTERNAL_COMMENTER,
          Collaborators.ROLES.EXTERNAL_EDITOR,
          // DOS-1299: External Team Redesign
          //Collaborators.ROLES.EXTERNAL_REVIEWER,
        ];
        roles[Steps.TYPES.EXTERNAL_REVIEW] = [
          Collaborators.ROLES.EXTERNAL_REVIEWER,
        ];
        roles[Steps.TYPES.COMBINE] = [
          Collaborators.ROLES.COMBINER,
        ];

        context.commit("setCollaboratorRoles",roles);
        resolve();
      });
    },

    /**
     * Adds a new task.
     *
     * Expected parameters:
     *
     * params = {
     *   collaboratorId: "String",
     *   description: "String",
     *   stepId: "String",
     *   taskRole: "String"
     * }
     *
     * @param {*} context
     * @param {*} params data of the new task
     */

    async addTask(context, {params, commitToStore}) {
      // If there already was a scheduled project reload, cancel it because it
      // might load old data.
      Commons.cancel(this.scheduledProjectReload);
      this.scheduledProjectReloadCanceled = true;

      // Commit the change right away so the UI will be updated. Some of the
      // data may be missing but the state will be refreshed when the REST API
      // call returns. This way the UI updates immediately instead of waiting
      // for API calls to return.

      if(commitToStore)
        context.commit("addTask", params);

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.post('v1/tasks/new', params)
        logger.debug("addTask succeeded with result:", result);

        // Note: Does this still work if there are several tasks without
        //       database identity in the store state? Notice that store
        //       mutator uses the task identity to fetch the right
        //       task to update.
        // FIXME: See DOS-2172
        context.commit("updateTask", result.data);

        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);

        this.scheduledProjectReloadCanceled = false;

        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("addTask didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        context.dispatch("scheduleProjectReload");

        throw error;
      }
    },

    async getTask(context, taskId) {

      try {
        const result = await apiCall.get('v1/tasks/' + taskId);

        context.commit("updateTask", result.data);

        return result;
      }
      catch(error) {
        logger.error("Query getTask didn't succeed with message:",error);
        throw error;
      }
    },

    /**
     * Call back-end to update a task description.
     *
     * Expected parameters:
     *
     * params = {
     *   "id": String (task id),
     *   "description": String (task description)
     * }
     */
    async updateTaskDescription(context, params) {

      try {
        // Update the Vuex state right away.
        // Note: See issue DOS-1426.

        context.commit("updateTaskDescription",params);

        const result = await apiCall.put('v1/tasks/update-description', params)
        logger.debug("updateTask succeeded with result:", result);

        // No need to reload the project data as we have already updated state. Reload the project
        // data only if the update API call fails.

        // context.dispatch("scheduleProjectReload");
        return result;
      }
      catch(error) {
        logger.error("updateTask failed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        context.dispatch("getProject");

        throw error;
      }

    },

    /**
     * Get validation result for task document.
     *
     * Expected parameter:
     *
     * params = {
     *   taskId: String,
     * }
     */
    async getDocumentValidationResult(context, params) {
      let url = 'v1/tasks/' + params.taskId + '/validate-document';

      try {
        const result = await apiCall.post(url);
        logger.debug("Document validation result:", result);

        return result.data;
      }
      catch(error) {
        logger.error("getDocumentValidation failed:", error);
        throw error;
      }
    },

    /**
     * Mark one delivery task as "Complete"
     *
     * This might result in several outcomes depending on situations:
     * - If the task is the last incomplete task in the Step, mark
     *   the whole Step as Complete.
     * - If the "params" has "completeStep=true", mark the Step as Complete
     *   (even if there are incomplete tasks).
     * - If the Step has next Step in the same Phase, activate the next Step.
     * - If the Step is the last Step in the Phase, then activate the next
     *   Phase.
     *
     * More information can be found in DOS-169, DOS-498 and DOS-933.
     *
     * Expected parameter:
     *
     * params = {
     *   taskId: String,
     *   completeStep: Boolean,
     *   message: String,
     *   emailDelivery: { // Optional delivery action.
     *     message: String (optional),
     *     recipients: String[],
     *     signedVersion: Boolean,
     *     subject: String (optional)
     *   }
     * }
     */
    async setDeliveryTaskAsComplete(context, params) {

      let url = 'v1/tasks/complete/delivery';

      // This will make it possible to visualize e.g. in ProjectTaskStatus component that we have
      // started the "complete" process but it is not yet ready.
      // Note: See DOS-1440.

      context.commit("ui/ADD_TASK_COMPLETING",params.taskId);

      try {
        const result = await apiCall.put(url, params)
        logger.debug("setDeliveryTaskAsComplete succeeded:", result);

        // Reload project data so the data has the latest Workflow data.

        context.dispatch("getProject").then(() => {
          context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);
        });
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

        logger.error("setDeliveryTaskAsComplete failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        throw error;
      }
    },

    /**
     * Mark one sign task as "Complete"
     *
     * This might result in several outcomes depending on situations:
     * - If the task is the last incomplete task in the Step, mark
     *   the whole Step as Complete.
     * - If the "params" has "completeStep=true", mark the Step as Complete
     *   (even if there are incomplete tasks).
     * - If the Step has next Step in the same Phase, activate the next Step.
     * - If the Step is the last Step in the Phase, then activate the next
     *   Phase.
     *
     * More information can be found in DOS-169, DOS-498 and DOS-933.
     *
     * Expected parameter:
     *
     * params = {
     *   taskId: String,
     *   completeStep: Boolean,
     *   message: String,
     *   signerDetails: { // Optional signer data to send with the request.
     *     fullName: String,
     *     organization: String,
     *     title: String
     *   }
     * }
     */
    async setSignTaskAsComplete(context, params) {

      // TODO: Should the name of the action be "setSignTaskAsCompleted"?

      let url = 'v1/tasks/complete/sign';

      // This will make it possible to visualize e.g. in ProjectTaskStatus component that we have
      // started the "complete" process but it is not yet ready.
      // Note: See DOS-1440.

      context.commit("ui/ADD_TASK_COMPLETING",params.taskId);

      try {
        const result = await apiCall.put(url, params)
        logger.debug("setSignTaskAsComplete succeeded:", result);

        // Reload project data so the data has the latest Workflow data.

        context.dispatch("getProject").then(() => {

          let mutationParams = {
            taskId: params.taskId,
            taskStatus: Tasks.STATUS.COMPLETED
          };
          context.commit("updateTaskStatus",mutationParams);

          context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);
        });
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

        logger.error("setSignTaskAsComplete failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Mark a combine task as "Complete".
     *
     * This might result in several outcomes depending on situations:
     * - If the Step has next Step in the same Phase, activate the next Step.
     * - If the Step is the last Step in the Phase, then activate the next Phase.
     *
     * More information can be found in DOS-3485.
     *
     * Expected parameter:
     *
     * params = {
     *   taskId: String,
     *   completeStep: Boolean,
     * }
     */
    async setCombineTaskAsComplete(context, params) {

      let url = 'v1/tasks/complete/combine';

      // This will make it possible to visualize e.g. in ProjectTaskStatus component that we have
      // started the "complete" process but it is not yet ready.
      // Note: See DOS-1440.

      context.commit("ui/ADD_TASK_COMPLETING",params.taskId);

      try {
        const result = await apiCall.put(url, params);
        logger.debug("setCombineTaskAsComplete succeeded:", result);

        // Reload project data so the data has the latest Workflow data.
        await context.dispatch("getProject");

        context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

        logger.error("setCombineTaskAsComplete failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Mark one task as "Complete"
     *
     * This might result in several outcomes depending on situations:
     * - If the task is the last incomplete task in the Step, mark
     *   the whole Step as Complete.
     * - If the "params" has "completeStep=true", mark the Step as Complete
     *   (even if there are incomplete tasks).
     * - If the Step has next Step in the same Phase, activate the next Step.
     * - If the Step is the last Step in the Phase, then activate the next
     *   Phase.
     *
     * More information can be found in DOS-169 and DOS-498.
     *
     * Expected parameter:
     *
     * params = {
     *   taskId: String,
     *   completeStep: Boolean,
     *   message: String
     * }
     */
    async setTaskAsComplete(context, params) {

      // TODO: Should the name of the action be "setTaskAsCompleted"?

      let url = 'v1/tasks/complete';

      // This will make it possible to visualize e.g. in ProjectTaskStatus component that we have
      // started the "complete" process but it is not yet ready.
      // Note: See DOS-1440.

      context.commit("ui/ADD_TASK_COMPLETING",params.taskId);

      try {
        const result = await apiCall.put(url, params)
        logger.debug("setTaskAsComplete succeeded:", result);

        let mutationParams = {
          taskId: params.taskId,
          taskStatus: Tasks.STATUS.COMPLETED
        };
        context.commit("updateTaskStatus",mutationParams);
        context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

        // Reload project data so the data has the latest Workflow data.

        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

        logger.error("setTaskAsComplete failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Marks one task as "In-Progress".
     *
     * This is currently mainly for just visual purposes. The "Available" tasks will turn to
     * "In-progress" state when end-user opens the Task. This is simply to signal that the
     * work on the Task has started.
     *
     * More information can be found in ticket DOS-2576.
     */
     async markTaskInProgress(context, taskId) {
      let url = 'v1/tasks/in-progress';

      try {
        let params = {
          taskId : taskId
        };
        const result = await apiCall.put(url, params);
        logger.debug("markTaskInProgress succeeded:", result);

        let mutationParams = {
          taskId: params.taskId,
          taskStatus: Tasks.STATUS.IN_PROGRESS
        };

        context.commit("updateTaskStatus",mutationParams); // Update state.

        return result;
      }
      catch(error) {
        logger.error("markTaskInProgress failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Marks a task as "Rejected".
     *
     * Expected parameters:
     *
     * params = {
     *   "taskId": String,
     *   "message": String
     * }
     *
     * More information can be found in DOS-593 and DOS-594.
     *
     * @returns {Promise} promise
     */
    async setTaskAsRejected(context, params) {

      let url = 'v1/tasks/reject/task';

      // This will make it possible to visualize e.g. in ProjectTaskStatus component that we have
      // started the "complete" process but it is not yet ready.
      // TODO: Consider adding a new mutator "ADD_TASK_REJECTING" if needed.
      // Note: See DOS-1440.

      context.commit("ui/ADD_TASK_COMPLETING",params.taskId);

      try {
        const result = await apiCall.put(url, params)
        logger.debug("setTaskAsRejected succeeded:", result);

        // Reload project data so the data has the latest Workflow data.

        context.dispatch("getProject").then(() => {
          context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);
        });
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

        logger.error("setTaskAsRejected failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Resolves a Task "rejection".
     *
     * Expected parameters:
     *
     * params = {
     *   "taskId": String,
     *   "message": String,
     *   "resolvationStrategy": String (see tasks.js#RESOLVATION_STRATEGY),
     *   "collaboratorIds": Array,
     *   "documentId": String,
     *   "stepId": String
     * }
     *
     * Do note that collaboratorId, documentId and stepId are used/required
     * only if the RESOLVATION_STRATEGY requires it.
     *
     * More information can be found in DOS-730.
     *
     * @returns {Promise} promise
     */
    async resolveTaskRejection(context, params) {
      let url = 'v1/tasks/resolve/task';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("resolveTaskRejection succeeded:", result);
      }
      catch(error) {
        logger.error("resolveTaskRejection failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Reverts a Document to a specific Document Revision.
     *
     * Expected parameters:
     *
     * params = {
     *   "documentId": String,
     *   "revisionId": String
     * }
     *
     * @returns {Promise} promise
     */
    async revertDocumentToRevision(context, params) {
      let url = 'v1/documents/project/revert';

      try {
        const result = await apiCall.post(url, params);
        logger.debug("revertDocumentToRevision succeeded:",result);

        // Reload project data.
        // TODO: Instead of this we should just commit the response.data
        //       to State.

        await context.dispatch("getProject");
        return result.data;
      }
      catch(error) {
        logger.error("resolveTaskRejection failed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Calls back-end to delete a task.
     */
    async deleteTask(context, params) {

      // If there already was a scheduled project reload, cancel it because it
      // might load old data. The API call will schedule a new reload anyway. We
      // wish to make the full data reload _after_ all changes, not during them.

      Commons.cancel(this.scheduledProjectReload);
      this.scheduledProjectReloadCanceled = false;

      let callParams = {
        "taskId": params.taskId
      }

      // Commit the change right away so the UI will be updated. Some of the
      // data may be missing but the state will be refreshed when the REST API
      // call returns. This way the UI updates immediately instead of waiting
      // for API calls to return.

      context.commit("deleteTask", params);

      try {
        context.commit("ui/ADD_WORKFLOW_SAVING");
        const result = await apiCall.delete('v1/tasks/delete', {
          data: callParams
        })
        logger.debug("deleteTask succeeded with result:", result);
        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);

        // DOS-2595: don't reload the project if next phase is delivery because otherwise UI will
        // first delete task, update task back and delete task again
        if(params.noReloadProject)
          return result;

        // Deleting a Task may in some cases put Project into Paused state. Reload Project data because of that.
        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("deleteTask failed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        context.dispatch("scheduleProjectReload");

        throw error;
      }

    },

    /**
     * Update User's role in a Step Task.
     *
     * Expected parameter:
     * params = {
     *  "taskId": "string",
     *  "newRoleName": "string",
     *  "organizationRef": "string",
     *  "stepId": "string"
     * }
     */
    async updateTaskRole(context, params) {

      // If there already was a scheduled project reload, cancel it because it
      // might load old data. The API call will schedule a new reload anyway. We
      // wish to make the full data reload _after_ all changes, not during them.

      Commons.cancel(this.scheduledProjectReload);
      this.scheduledProjectReloadCanceled = true;

      // Commit the change right away so the UI will be updated with tentative
      // information. When REST API call returns the UI will be updated with
      // authoritative DB data.

      let mutationParams = {
        "stepId": params.stepId,
        "taskRole": params.newRoleName,
        "taskId": params.taskId
      };

      context.commit("updateTask", mutationParams);

      let apiParams = {
        "taskId": params.taskId,
        "newRoleName": params.newRoleName
      };

      let url = 'v1/tasks/role/change';

      try {
        const result = await apiCall.patch(url, apiParams)
        logger.debug("updateTaskRole succeeded with result:",
                            result);

        // Reload project data so the data has the latest Workflow data
        context.dispatch("scheduleProjectReload");
        return result;
      }
      catch(error) {
        logger.error("updateTaskRole failed with message:",error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Uploads files to server and then refresh the Project data.
     *
     * Do note that the params.formData may contain multiple files. Multiple
     * files are uploaded one after another to server.
     *
     * Do note that if the "projectId" and "fileLocation" are null/undefined then the upload
     * document will be considered a User's private Document. Otherwise it will be considered
     * a Project Document. For more information see DOS-1601.
     *
     * Expected parameters:
     *
     * params = {
     *   formData: (FormData),
     *   projectId: (String),
     *   fileLocation: (String),
     *   onUploadFail: (Function)
     * }
     *
     * @params context
     * @params params
     *
     * @returns {Promise} promise
     */

    uploadDocument(context, params) {
      // TODO: It would be best to create two different methods for uploading a) Project Documents
      //       and b) User Documents. Reason why they are combined is because they share a lot of
      //       code. Consider splitting this method into two different methods that call third
      //       method to actually perform the upload.

      let formData = params.formData;
      let failCallBack = params.onUploadFail;

      let filesArray = formData.getAll("file"); // Note: getAll()
      let projectId = params.projectId;
      let fileLocation = params.fileLocation;

      let responsesArray =[];

      let vueInstance = this;

      // Whether we are uploading a User specific Document instead of a Project Document.
      // TODO: This is not explicit enough.

      let isUserDocumentUpload = projectId === null && fileLocation === null;

      // Before starting the uploading add all the candidate files to UI State
      // as "FILE_UPLOAD_ITEMS" so their progress can be tracked. This will be
      // visualized as a File upload progress bar.
      // Note: See DocumillUploadProgress.vue.

      filesArray.forEach(function(file) {
        if(file.skipFile !== true)
          context.commit("ui/ADD_FILE_UPLOAD_ITEM",file.name);
      });

      // This look complicated but just performs a reducer function to each
      // value in the array and returns a promise. The reducer function
      // uploads each file one by one to back-end.

      return new Promise((resolve) => {
        filesArray.reduce(function(promise,file) {

          return promise.then(function() {
            // For the "skipFile" see documentMixin.js#filterTooLargeFiles and DOS-2295.
            if(file.skipFile)
              return; // Skip sending file.

            let apiFormData = new FormData();

            let fileName = file.name;
            let url;

            apiFormData.append("file",file,fileName);

            if(isUserDocumentUpload) { // Uploading a User Document?
              url = 'v1/documents/user/upload';
            }
            else { // Uploading a Project Document?
              apiFormData.append("projectId",projectId);
              apiFormData.append("fileLocation",fileLocation);

              url = 'v1/documents/project/uploadFile';
            }

            logger.debug("uploadDocument: Sending "+ fileName+" to",
                                        fileLocation);

            // Configure onUploadProgress event listener. It will inform the UI
            // state about the file upload progress.

            let axiosConf = {
              onUploadProgress: function( event ) {
                let uploadPct =
                  parseInt(Math.round(( event.loaded * 100 ) / event.total ));

                // TODO: Upload progress bar does not appear for some reason if value
                //       stays the same, for example if you set uploadPct = 50 here.

                let stateParams = { fileName: fileName, progress: uploadPct };
                context.commit("ui/UPDATE_FILE_UPLOAD_PROGRESS",stateParams);
              }.bind(this)
            };

            return apiCall.post(url, apiFormData, axiosConf)
              .then(response=> {  // A single file upload succeeded.
                logger.debug("uploadDocument: Upload succeeded:",
                                            response);

                // Add the Project Document to state right away.

                if(isUserDocumentUpload)
                  context.commit("ADD_USER_DOCUMENT",response.data);
                else
                  context.commit("addDocument",response.data);
                responsesArray.push(response);
                return response;
              })
              .catch(error => {  // A single file upload failed
                failCallBack(error,file);

                context.commit("ui/REMOVE_FILE_UPLOAD_ITEM",file.name);

                logger.debug("uploadDocument: Upload failed:",error);
                return error;
              })
          });
        }, Promise.resolve())
        .then(response => {
          // Upload ended.

          logger.debug("uploadDocument: Upload ended");

          context.dispatch("scheduleProjectReload");

          // When the upload becomes ready, clear out the state.

          filesArray.forEach(function(file) {
            context.commit("ui/REMOVE_FILE_UPLOAD_ITEM",file.name);
          });

          resolve(responsesArray);
        })
      });
    },

    /**
     * Uploads a file to server as a new Document Revision. This will become
     * the latest Revision for the target Document.
     *
     * This is mostly used for non-editable content. For example if user is
     * working with images he/she may upload a new Revision of the image.
     *
     * Expected parameters:
     *
     * params = {
     *   documentId: String,
     *   formData: FormData
     * }
     *
     * @params context
     * @params params
     *
     * @returns {Promise} promise
     */

    uploadDocumentRevision(context, params) {
      let formData = params.formData;

      let file = formData.get("file");
      let documentId = params.documentId;

      let vueInstance = this;

      // Before starting the uploading add all the candidate files to UI State
      // as "FILE_UPLOAD_ITEMS" so their progress can be tracked. This will be
      // visualized as a File upload progress bar.
      // Note: See DocumillUploadProgress.vue.

      context.commit("ui/ADD_FILE_UPLOAD_ITEM",file.name);

      return new Promise((resolve, reject) => {
        let apiFormData = new FormData();
        let fileName = file.name;

        apiFormData.append("revision",file,fileName);

        let url = 'v1/documents/project/upload/document/' + documentId + '/revision';

        logger.debug("uploadDocumentRevision: Uploading " +
                                   fileName);

        // Configure onUploadProgress event listener. It will inform the UI
        // state about the file upload progress.

        let axiosConf = {
          onUploadProgress: function( event ) {
            let uploadPct =
              parseInt(Math.round(( event.loaded * 100 ) / event.total ));

            let stateParams = { fileName: fileName, progress: uploadPct };
            context.commit("ui/UPDATE_FILE_UPLOAD_PROGRESS",stateParams);
          }.bind(this)
        };

        return apiCall.post(url, apiFormData, axiosConf)
          .then(response=> {  // File upload succeeded.
            logger.debug("uploadDocument: Upload succeeded:",
                                        response);

            context.commit("ui/REMOVE_FILE_UPLOAD_ITEM",file.name);

            // TODO: Put the revision to state right away. We probably would
            //       need to replace the existing Document with the
            //       response.data Document data. Remove the "getProject"
            //       call after that.

            // context.commit("putDocumentRevision",response.data);

            context.dispatch("getProject").then(response => {
              resolve(response);
            });
          })
          .catch(error => {  // File upload failed
            context.commit("ui/REMOVE_FILE_UPLOAD_ITEM",file.name);

            logger.debug("uploadDocument: Upload failed:",error);
            reject(error);
          })
      });
    },

    /**
     * Uploads the visual signature to back-end.
     *
     * Expected parameters:
     *
     * params = {
     *   collaboratorId: String,
     *   signatureTemplateId: String,
     *   signatureBlob: Blob (signature image)
     * }
     */
    async uploadVisualSignature(context,params) {
      let apiFormData = new FormData();
      apiFormData.append("signature",params.signatureBlob);
      apiFormData.append("signatureTemplateId",params.signatureTemplateId);

      let collaboratorId = params.collaboratorId;
      let collaborator = context.getters.getCollaboratorById(collaboratorId);

      let url = 'v1/signer-details/' + collaboratorId + '/signature';

      let signerDetails = collaborator.signerDetails;
      let signerDetailsClone = {...signerDetails};

      try {
        // Update state.

        signerDetailsClone.definesVisualSignature = true;
        signerDetailsClone.signatureTemplateId = params.signatureTemplateId; // DOS-3052.

        params = {
          "id": collaboratorId,
          "signerDetails": signerDetailsClone
        };

        context.commit('updateCollaboratorSignerDetails',params);

        // Call back-end.

        const result = await apiCall.post(url, apiFormData);

        logger.debug("uploadSignature succeeded with result:", result);
        return result;
      }
      catch(error) {
        logger.error("uploadSignature failed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        // Reset state on back-end call failure.

        params = {
          "id": collaboratorId,
          "signerDetails": signerDetails
        };

        context.commit('updateCollaboratorSignerDetails',params);

        throw error;
      }
    },

    /**
     * Add extra file to a step.
     *
     * required params:
     * {
     *  "stepId": "string",
     *  "documentId": "string"
     * }
     *
     */
    async addExtraFileToStep(context, params) {
      let url = 'v1/steps/extra-documents/add';

      // TODO: Adding to state right away would make the UI update faster,
      //       but the sort offset logic from backend would need to be
      //       duplicated here (see StepService.recomputeStepExtraDocumentOffsets)

      try {
        const result = await apiCall.post(url, params);

        let updatedStep =  result.data;

        context.commit("setStepExtraDocuments",updatedStep);

        logger.debug("addExtraFileToStep succeeded with result:", result);
        return result;
      }
      catch(error) {
        logger.error("addExtraFileToStep failed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        // Reload project data right away because the UI data might be in
        // out of sync.

        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Remove an extra file from a Step.
     *
     */
    async removeExtraFileFromStep(context, params){
      let url = 'v1/steps/extra-documents/remove';

      // Commit changes right away so UI updates fast.

      context.commit("removeExtraFileFromStep", params);

      try {
        const result = await apiCall.post(url, params);

        logger.debug("removeExtraFileFromStep succeeded with result:", result);
        return result;
      }
      catch(error) {
        logger.error("removeExtraFileFromStep didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        // Reload project data right away because the UI data might be in
        // out of sync.

        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Updates the order of Extra Documents in a Step. Does not add or delete any Extra Document.
     * All referenced Extra Documents are expected to exist already in back-end.
     *
     * Note that "documentIds" array is expected also to include the Anchored Workflow Document.
     *
     * Required params:
     * {
     *  "stepId": "string",
     *  "documentIds": "array"
     * }
     *
     */
     async updateExtraFileOrder(context, params) {
      let url = 'v1/steps/extra-documents/reorder';

      // TODO: Adding to state right away would make the UI update faster, but the sort offset
      //       logic from backend would need to be duplicated here.

      try {
        const result = await apiCall.put(url, params);

        let updatedStep = result.data;

        context.commit("setStepExtraDocuments",updatedStep);

        logger.debug("updateExtraFileOrder succeeded with result:", result);
        return result;
      }
      catch(error) {
        logger.error("updateExtraFileOrder failed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        // Reload project data right away because the UI data might be in out of sync.

        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Attach Document to Workflow Anchor.
     *
     * This also results in all Tasks to be related with the attached Document.
     *
     * required params:
     * {
     *  "anchorId": "string",
     *  "documentId": "string"
     * }
     *
     */
    async attachDocument(context, params) {
      let url = 'v1/anchors/' + params.anchorId + '/attach';

      // If there already was a scheduled project reload, cancel it because it
      // might load old data. The API call will schedule a new reload anyway. We
      // wish to make the full data reload _after_ all changes, not during them.

      Commons.cancel(this.scheduledProjectReload);
      this.scheduledProjectReloadCanceled = true;

      // Commit changes right away so UI updates fast. We will load
      // authoritative state from backend with scheduled reload.

      context.commit("ui/ADD_WORKFLOW_SAVING");
      context.commit("attachDocument", params);

      try {
        let apiParams = {
          documentId: params.documentId
        };

        const result = await apiCall.put(url, apiParams);
        logger.debug("attachDocument succeeded with result:", result);
        this.scheduledProjectReloadCanceled = false;
        context.dispatch("getProject");

        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("attachDocument failed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        // Reload project data right away because the UI data might be in
        // out of sync.

        context.dispatch("getProject");
        throw error;
      }
    },

    /**
     * Detach an Document from an Anchor.
     */
    async detachDocument(context, anchorId) {
      let url = 'v1/anchors/' + anchorId + '/detach';

      // If there already was a scheduled project reload, cancel it because it
      // might load old data. The API call will schedule a new reload anyway. We
      // wish to make the full data reload _after_ all changes, not during them.

      Commons.cancel(this.scheduledProjectReload);
      this.scheduledProjectReloadCanceled = true;

      // Commit changes right away so UI updates fast. We will load
      // authoritative state from backend with scheduled reload.
      context.commit("ui/ADD_WORKFLOW_SAVING");
      context.commit("detachDocument", anchorId);

      try {
        const result = await apiCall.put(url);

        logger.debug("removeAttachmentFromAnAnchor succeeded with result:", result);
        this.scheduledProjectReloadCanceled = false;

        // Detaching a document may in some cases put Project into Planning. Reload Project data because of that.
        context.dispatch("getProject");

        context.commit("ui/REMOVE_WORKFLOW_SAVING",true);
        return result;
      }
      catch(error) {
        context.commit("ui/REMOVE_WORKFLOW_SAVING",false);
        logger.error("removeAttachmentFromAnAnchor didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);

        // Reload project data right away because the UI data might be in
        // out of sync.

        context.dispatch("getProject");

        throw error;
      }
    },

    /**
     * Moves a Project Document to current User's private Documents.
     *
     * @param {String} documentId project document identity
     */
    async moveDocumentToUserDocuments(context, documentId) {

      let url = 'v1/documents/project/move-to-user/' + documentId;

      try {
        const result = await apiCall.patch(url);
        logger.debug("Document move to user documents succeeded with result:",result);

        // Add the new User Document to Vuex state. See userInfo.js for more information.
        // Note: Also remove the input Project Document from state as it has been "moved".

        context.commit("ADD_USER_DOCUMENT",result.data);
        context.commit("deleteDocument",documentId);

        return result;
      }
      catch(error) {
        logger.error("Document move to user documents failed:",error);
        throw error;
      }
    },

    /**
     * Copies a Project Document to current User's private Documents.
     *
     * @param {String} documentId project document identity
     */
    async copyDocumentToUserDocuments(context, documentId) {

      let url = 'v1/documents/project/copy-to-user/' + documentId;

      try {
        const result = await apiCall.patch(url);
        logger.debug("Copying the document to user documents was successful with a result:",result);

        context.commit("ADD_USER_DOCUMENT",result.data);

        return result.data;
      }
      catch(error) {
        logger.error("Document copy to user documents failed:",error);
        throw error;
      }
    },

    /**
     * Update file location information.
     *
     * The input argument "params"-object should define
     * "documentId", "fileLocation"
     */
    async updateFileLocation(context, params) {

      let apiParams = {
        "documentId": params.documentId,
        "fileLocation": params.locationName
      }

      let url = 'v1/documents/project/location/change';

      try {
        const result = await apiCall.patch(url, apiParams)
        logger.debug("File location change succeeded with result:",
                          result);
        // Reload project data
        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("File location change didn't succeed:",
                          error);
        throw error;
      }
    },

    /**
     * Update the file name.
     *
     * The input argument "params"-object should define
     * "documentId", "fileName"
     */
    async updateFileName(context, params) {

      let apiParams = {
        "documentId": params.documentId,
        "fileName": params.fileName
      }
      let url = 'v1/documents/project/filename/change';

      try {
        const result = await apiCall.patch(url, apiParams)
        logger.debug("File name change succeeded with result:",
                          result);
        // Reload project data
        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("File name change didn't succeed:",
                          error);
        throw error;
      }
    },

     /**
     * Copy file to Private folder or duplicate file.
     *
     * Expected parameters:
     *
     * params = {
     *   documentId: String,
     *   fileLocation: String
     * }
     */
    async copyDuplicateFile (context, params) {
      let url = 'v1/documents/project/copyFile';

      try {
        const result = await apiCall.post(url, params)
        logger.debug("File copy/duplicate succeeded with result:",
                          result);
        // Reload project data
        context.dispatch("getProject");
        return result;
      }
      catch(error) {
        logger.error("File copy/duplicate didn't succeed:",
                          error);
        throw error;
      }
    },

    /**
     * Create document from template.
     *
     * Expected parameters:
     *
     * params = {
     *   projectId: String,
     *   documentTemplateType: String,
     *   title: String
     * }
     */
    async createDocumentFromTemplate(context, params) {
      let url = 'v1/documents/project/createDocumentFromTemplate';

      try {
        const result = await apiCall.post(url, params)
        logger.debug("Create document from template succeeded with result:", result);
        return result;
      }
      catch(error) {
        logger.error("Create document from template didn't succeed:", error);
        throw error;
      }
    },

    /**
     * Add a new Anchor to Workflow of a Project
     *
     * required params:
     * {
     *  "projectRef": projectId
     * }
     *
     */
    async addAnchor(context, params) {
      let url = 'v1/anchors/new';

      try {
        const result = await apiCall.post(url, params)
        logger.debug("createNewAnchor succeeded with result:", result);
        this.commit("addAnchor", result.data);
        return result;
      }
      catch(error) {
        logger.error("createNewAnchor didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /** Update the project state.
     *
     * Replace project planned state with "newSchedule" data.
     *
     * @param context
     * @param newSchedule
     */

    updateProjectPlannedSchedule(context, newSchedule) {

      context.commit("updateProjectPlannedSchedule", newSchedule);
      context.dispatch("updateSchedule", newSchedule);
    },


    /** Update a given schedule.
     *
     * Notice that the store state should be separately udpated when
     * calling this function.
     *
     * Expects all the fields to be specified.
     *
     *  {string} "id"
     *  {int} "duration"
     *  {date} "endDate"
     *  {date} "startDate"
     *
     * @param {*} context
     * @param {*} newSchedule
     */

    async updateSchedule(context, newSchedule) {
      let url = 'v1/schedules/update';

      try {
        const result = await apiCall.put(url, newSchedule)
        logger.debug("Update Schedule succeeded with result:", result);
        return result;
      }
      catch(error) {
        logger.error("Update Schedule didn't succeed with message:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        context.dispatch("getProject"); // Forced update right away.
        throw error;
       }
    },

    /**
     * Add a "blank" workflow into a Project.
     *
     * A "blank" workflow contains one anchor with the following Phases:
     * - Editing Phase (with an Edit Step and a Review Step)
     * - Signing Phase (with a Sign Step)
     * - Delivery Phase (with a Delivery Step)
     *
     * This function adds this "blank" workflow into Project that is currently in the Store.
     */
    async addBlankWorkflow(context) {
      try
      {
        // Add an Edit Phase.

        let paramsPhase = {
          "customName": i18n.global.t("project_workflow.default_workflow.phase_name_edit"),
          "phaseStatus": Phases.STATUS.UPCOMING,
          "phaseType": Phases.TYPES.EDITING,
          "projectRef": context.state.project.id
        }

        let response = await context.dispatch("addPhase", paramsPhase);

        let newPhase = context.state.project.phases[0];

        // Add an Anchor.

        let paramsAnchor = {"projectRef": context.state.project.id}
        response = await context.dispatch("addAnchor", paramsAnchor);
        let newAnchor = response.data;

        // Add an Edit Step.

        let paramsEditStep = {
          phaseRef: newPhase.id,
          phaseType: newPhase.phaseType,
          goal: Steps.TYPES.EDIT,
          anchorRef: newAnchor.id
        }

        response = await context.dispatch("addStep", paramsEditStep);

        // Then add a Review Step after that

        let paramsReviewStep = {
          anchorRef: newAnchor.id,
          goal: Steps.TYPES.REVIEW,
          phaseRef: response.data.phaseRef,
          previous: response.data.id,
          stepStatus: Steps.STATUS.UPCOMING
        };

        response = await context.dispatch("addStep", paramsReviewStep);

        let reviewStepId = response.data.id;

        // Add a Signing Phase.

        let paramsSigningPhase = {
          "customName": i18n.global.t("project_workflow.default_workflow.phase_name_signing"),
          "phaseStatus": Phases.STATUS.UPCOMING,
          "phaseType": Phases.TYPES.SIGNING,
          "projectRef": context.state.project.id,
          "previous": response.data.phaseRef,
        }

        response = await context.dispatch("addPhase", paramsSigningPhase);

        // Add a Sign Step.

        let paramsSigningStep = {
          anchorRef: newAnchor.id,
          goal: Steps.TYPES.SIGN,
          phaseRef: response.data.id,
          previous: reviewStepId,
          stepStatus: Steps.STATUS.UPCOMING
        };

        response = await context.dispatch("addStep", paramsSigningStep);

        let signStepId = response.data.id;

        // Add a Delivery Phase

        let paramsDeliveryPhase = {
          "customName": i18n.global.t("project_workflow.default_workflow.phase_name_delivery"),
          "phaseStatus": Phases.STATUS.UPCOMING,
          "phaseType": Phases.TYPES.DELIVERING,
          "projectRef": context.state.project.id,
          "previous": response.data.phaseRef,
        }

        response = await context.dispatch("addPhase", paramsDeliveryPhase);

        // Add a Deliver step

        let paramsDeliverStep = {
          anchorRef: newAnchor.id,
          goal: Steps.TYPES.DELIVER,
          phaseRef: response.data.id,
          previous: signStepId,
          stepStatus: Steps.STATUS.UPCOMING
        };

        response = await context.dispatch("addStep", paramsDeliverStep);

        let deliverStep = response.data;

        // DOS-2067: Assign the project owner(s) to the Deliver step, if a project owner exists

        let projectOwners = context.getters.getProjectOwners;

        for(let projectOwner of projectOwners) {
          let params = {
            collaboratorId: projectOwner.id,
            description: "",
            stepId: deliverStep.id,
            taskRole: Steps.getDefaultRoleForStepType(deliverStep.goal),
            taskStatus: ( deliverStep.stepStatus == Steps.STATUS.ACTIVE ) ? Tasks.STATUS.AVAILABLE : Tasks.STATUS.UPCOMING,
          };

          let commitToStore = true;

          response = await context.dispatch("addTask", {params, commitToStore});
          let createdTask = response.data;

          // Mark the affected task/step as autopopulated so that it can be shown in the UI.
          await context.dispatch("markTaskAutopopulated",{ taskId: createdTask.id, stepId: createdTask.stepId });
        }

        response = await context.dispatch("getProject");

        return response.data;
      }
      catch(error) {
        logger.error("Adding blank workflow didn't succeed with message:", error);
        throw error;
      };
    },

    /** Gets the names of the documents both in the selected template workflow
     * and the current project.
     *
     * @param {*} context
     * @param {*} templateId
     *
     */


    async getConflictingDocumentNames(context,templateId) {
      let params = {params:{"projectId":context.state.project.id,
                            "templateId" : templateId}
                   };
      try {
        const result = await apiCall.get('/v1/documents/project/list/conflictingdocuments', params )
        logger.debug("Getting conflicting files succeeded:", result);
        return result;
      }
      catch(error) {
        logger.error("Getting conflicting files did not succeed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /** Gets task history by task database id.
     *
     * @param {*} context
     * @param {*} taskId task database identity
     */

    async getTaskHistory(context, taskId) {

      try {
        const result = await apiCall.get('/v1/taskevents/task/' + taskId + '/list')
        logger.debug("Getting task history succeeded", result);
        return result;
      }
      catch(error) {
        logger.error("Getting task history did not succeed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Gets document revision by database identity and converts to blob object.
     *
     * @param {*} context
     * @param {*} revisionId revision database identity
     */

    async getRevisionBlob(context, revisionId) {

      try {
        const url = '/v1/documents/project/download/revision/' + revisionId;
        const result = await apiCall.get(url, { responseType: 'arraybuffer' });

        const mimeType = await result.headers["content-type"];

        const objectUrl =
          await window.URL.createObjectURL(new Blob([result.data], {type: mimeType}));

        return objectUrl;
      }
      catch(error) {
        logger.error("Getting revision did not succeed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Gets signed document revision by database identity and converts to blob object.
     *
     * @param {*} context
     * @param {*} revisionId signed revision identity
     */

    async getSignedRevisionBlob(context, revisionId) {

      try {
        const url = '/v1/documents/project/download/revision/signed/' + revisionId;
        const result = await apiCall.get(url, { responseType: 'arraybuffer' });

        const mimeType = await result.headers["content-type"];

        const objectUrl =
          await window.URL.createObjectURL(new Blob([result.data], {type: mimeType}));

        return objectUrl;
      }
      catch(error) {
        logger.error("Getting signed revision did not succeed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Gets User Document by database identity and converts it to a blob object.
     *
     * @param {*} context
     * @param {*} userDocumentId document database identity
     */

    async getUserDocumentBlob(context, userDocumentId) {

      try {
        const url = '/v1/documents/user/' + userDocumentId + '/download';
        const result = await apiCall.get(url, { responseType: 'arraybuffer' });

        const mimeType = await result.headers["content-type"];

        const objectUrl =
          await window.URL.createObjectURL(new Blob([result.data], {type: mimeType}));

        return objectUrl;
      }
      catch(error) {
        logger.error("Getting user document did not succeed:", error);
        logger.error(error.response.data);
        logger.error(error.response.status);
        logger.error(error.response.headers);
        throw error;
      }
    },

    /**
     * Notifies task collaborator about task in progress
     *
     * @param {*} context context
     * @param {String} taskId task identity
     *
     * @returns {Promise} promise
    */

    async notifyTaskInProgress(context, taskId) {

      let url= 'v1/tasks/'+ taskId + '/notify-in-progress'

      try {
        const result = await apiCall.patch(url)
        logger.debug("Task in progress notification succeeded with result:",
                            result);
        return result;
      }
      catch(error) {
        logger.error("Task in progress notification failed with message:",
                            error);
        throw error;
      }
    },


    /**
     * Shares a task.
     *
     *
     * @param {*} context context
     * params = {
     *   // UserId or email should not be null
     *   userId: String, (if any)
     *   email: String,
     *   taskId: String
     * }
     *
     * @returns {Promise} promise
    */

    async shareTask(context, params) {

      let url= 'v1/tasks/share'

      try {
        const result = await apiCall.put(url, params)
        logger.debug("Task sharing succeeded with result:",
                            result);
        return result;
      }
      catch(error) {
        logger.error("Task sharing failed with message:",
                            error);
        throw error;
      }
    },


    /**
     * Stop sharing a task.
     *
     *
     * @param {*} context context
     * @param {String} taskId task identity
     *
     * @returns {Promise} promise
    */

   async unShareTask(context, taskShareToken) {

    let url= 'v1/tasks/'+ taskShareToken + '/unshare'

    try {
      const result = await apiCall.put(url)
      logger.debug("Task un-sharing succeeded with result:",
                          result);
      return result;
    }
    catch(error) {
      logger.error("Task un-sharing failed with message:",
                          error);
      throw error;
    }
  },

     /**
     * Get task's task shares.
     *
     *
     * @param {*} context context
     * @param {String} taskId task identity
     *
     * @returns {Promise} promise
    */

   async getTaskShares(context, taskId) {

    let url= 'v1/tasks/'+ taskId + '/task-shares'

    try {
      const result = await apiCall.get(url)
      logger.debug("Getting task shares succeeded with result:",
                          result);
      return result;
    }
    catch(error) {
      logger.error("Getting task shares failed with message:",
                          error);
      throw error;
    }
  },

     /**
     * Get task share.
     *
     *
     * @param {*} context context
     * @param {String} taskShareToken task share token
     *
     * @returns {Promise} promise
    */

     async getTaskShare(context, taskShareToken) {

      let url= 'v1/tasks/'+ taskShareToken + '/share'

      try {
        const result = await apiCall.get(url)
        logger.debug("Getting task sharing data succeeded with result:",
                            result);
        return result;
      }
      catch(error) {
        logger.error("Getting task sharing data failed with message:",
                            error);
        throw error;
      }
    },

  async addProjectToGroup(context, params) {
    let url = 'v1/groups/add-project-to-group';

    let apiParams = {
      groupId: params.group.id,
      projectId: params.projectId
    }

    try {
      const result = await apiCall.put(url, apiParams)
      logger.debug("Add a project to a group succeed: ",result);
      context.commit("ADD_PROJECT_TO_GROUP", params);
      return result;
    }
    catch(error) {
      logger.error("Add a project to a group didn't succeed with message:",error);
      throw error;
    }
  },

  async deleteProjectFromGroup(context, params) {
    let url = 'v1/groups/delete-project-from-group';

    try {
      const result = await apiCall.delete(url, {data: params})
      logger.debug("Delete a project from group succeed:",result);
      if(this.getters.getProjectGroups)
        context.commit("DELETE_PROJECT_FROM_GROUP", params);
      return result;
    }
    catch(error) {
      logger.error("Delete a project from group didn't succeed with message:",error);
      throw error;
    }
  },

  async assignProjectOwnersToDeliverySteps(context, params) {
    // DOS-2547: Auto-populate project owner(s) into delivery steps in the project
    let projectOwners = this.getters.getProjectOwners;
    let project = this.getters.getProject;

    for(let anchor of project.anchors) {
      for(let deliverStep of anchor.steps) {
        if(deliverStep.goal != Steps.TYPES.DELIVER)
          continue;

        let stepHasTasksAlready = false;

        for(let task of project.tasks) {
          if(deliverStep.id == task.stepId)
            stepHasTasksAlready = true;
        }

        if(stepHasTasksAlready)
          continue; // Skip this step as the workflow already defines recipients for this step

        for(let projectOwner of projectOwners) {
          let params = {
            collaboratorId: projectOwner.id,
            description: "",
            stepId: deliverStep.id,
            taskRole: Steps.getDefaultRoleForStepType(deliverStep.goal),
            taskStatus: ( deliverStep.stepStatus == Steps.STATUS.ACTIVE ) ? Tasks.STATUS.IN_PROGRESS: Tasks.STATUS.UPCOMING,
          };

          let commitToStore = true;

          await this.dispatch("addTask", {params, commitToStore}).then(response =>{
            let createdTask = response.data;
            // Mark the affected task/step as autopopulated so that it can be shown in the UI.
            this.dispatch("markTaskAutopopulated",{ taskId: createdTask.id, stepId: createdTask.stepId });
          })
        }

        // Add only to the first step
        break; // Skip to next anchor after handling first deliver step in this one
      }
    }
  },

  async assignCollaboratorsToDeliveryStep(context, params) {
    // DOS-2720: If quick start project auto-populate all collaborators into delivery step
    // There should be only 1 deliver step in quick start project

    let project = this.getters.getProject;
    let projectCollaborators = this.getters.getProjectCollaborators;
    // There should be only 1 anchor in quick start project
    let anchor = project.anchors[0];
    let deliverStep = anchor.steps.find(step => step.goal === Steps.TYPES.DELIVER);

    projectCollaborators.forEach(collaborator => {

      if(collaborator.collaboratorType == Collaborators.TYPES.BOT || collaborator.userDeactivated ||
        collaborator.projectRole === 'INACTIVE')
        return;

      let collaboratorAlreadyAssigned = false;
      project.tasks.forEach(task => {
        if(task.stepId == deliverStep.id && task.collaboratorId == collaborator.id)
          collaboratorAlreadyAssigned = true;
      });

      if(!collaboratorAlreadyAssigned){
        let params = {
          collaboratorId: collaborator.id,
          description: "",
          stepId: deliverStep.id,
          taskRole: Steps.getDefaultRoleForStepType(deliverStep.goal),
          taskStatus: Tasks.STATUS.UPCOMING,
        };
        let commitToStore = true;
        this.dispatch("addTask", {params, commitToStore}).then(() => {
        }).catch(error =>{
          this.dispatch("getProject");
          logger.error("Assign collaborators to delivery step didn't succeed with message:", error);
          throw error;
        })
      }
    });
  },

  /**
   * Restore the tasks's document by setting the document current revision to the latest
   * validated document export. Response code 409 indicates that the export is not ready and the
   * caller should try again.
   *
   * @param {*} context
   * @param {String} taskId
   *
   * @returns response
   */

  async recoverTaskDocument(context,taskId) {
    let url = 'v1/documents/project/recover/'+taskId;

    try {
      const result = await apiCall.post(url);
      logger.debug("Task document recovery succeeded:",result);
      return result;
    }
    catch(error) {
      if(error.response && error.response.status == "409")
        logger.debug("Task document recovery failed, you should try again:",error);
      else
        logger.error("Task document recovery failed with a message:",error);

      throw error;
    }
  },

  /**
   * Reopen the tasks by setting the tasks status IN_PROGRESS.
   *
   * @param {*} context
   * @param {String} taskId
   *
   * @returns response
   */
   async reopenTask(context,taskId) {
    let url = 'v1/tasks/reopen-task';

    let params = {
      taskId : taskId
    };

    context.commit("ui/ADD_TASK_COMPLETING",params.taskId);

    try {

      const result = await apiCall.put(url, params);
      logger.debug("Reopening a task succeeded:", result);

      context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);

      let mutationParams = {
        taskId: params.taskId,
        taskStatus: Tasks.STATUS.IN_PROGRESS
      };

      context.commit("updateTaskStatus",mutationParams); // Update state.

      return result;
    }
    catch(error) {
      logger.error("Reopening a task failed:", error);
      context.commit("ui/REMOVE_TASK_COMPLETING",params.taskId);
      throw error;
    }
  }



  },

  //
  //
  // End: Actions
  //
  //
}
