/**
 * Vue Store that holds data of the current Organization.
 *
 * @author Documill
 */

import apiCall from './../../utils/api';
import { logger } from '../../utils/logger';

export default {

  state: {
    /**
     * The data of a Organization that is currently selected.
     *
     * How to use:
     * this.$store.state.organization.organization
     */
    organization: {},

    /*
     * Array of identity providers
     */
    identityProviders: [],

    /**
     * Array of storage integrations.
     *
     * How to use:
     * this.$store.state.organization.storageIntegrations
     */
    storageIntegrations: [],

    /**
     * Array of external API keys.
     */
    externalApiKeys: [],

    /*
     * Organizations subscription history.
     */

    subscriptions: [],

    /**
     * Organization subscription limits and boolean values
     * whether these limits exceeded
     * (e-signature, user_licence, 'live' projects).
     */
    subscriptionLimits: {},

    /**
     * Array of groups in organization.
     */
    organizationGroups: [],

    /**
     * If identification is enabled.
     */
    isIdentificationEnabled: false,

    /**
     * Organization Signature page template.
     * Template layout data, what information about Signers and Organization
     * is included in Signature page.
     *
     * @see DOS-2955
     */
    signaturePageTemplate: {},

    /**
     * Salesforce Integration User.
     *
     * @see DOS-3479
     */
    salesforceIntegrationUser: [],
  },

  getters: {

    /**
     * Gets the current organization identity.
     *
     * How to use:
     * this.$store.getters.getOrganizationId
     */
    getOrganizationId: (state) => {
      return state.organization.id;
    },

    /**
     * Gets the current organization name.
     *
     * How to use:
     * this.$store.getters.getOrganizationName
     */
    getOrganizationName: (state) => {
      return state.organization.name;
    },

    getOrganizationUrlName: (state) => {
      return state.organization.urlName;
    },

    getOrganizationSubscriptions: (state) => {
      return state.subscriptions;
    },

    getOrganizationLocale: (state) => {
      return state.organization.locale;
    },

    isOrganizationMyFiles: (state) => {
      return state.organization.myFiles;
    },

    getOrganizationGroups: (state) => {
      return state.organizationGroups;
    },

    isIdentificationEnabled: (state) => {
      return state.isIdentificationEnabled;
    },

    getOrganizationSignaturePage: (state) => {
      return state.signaturePageTemplate;
    },

    getOrganizationLogoUrl: (state) => {
      return state.signaturePageTemplate.logoUrl;
    },

    isTaskSharingAndMention: (state) => {
      return state.organization.taskSharingAndMention;
    },

    getOrganizationSalesforceIntegration: (state) => {
      return state.salesforceIntegrationUser;
    },

    isEnforceIdentityProvider: (state) => {
      return state.organization.enforceIdentityProvider;
    },
  },

  mutations: {

    /**
     * Sets organization to state.
     *
     * @param {*} state
     * @param {*} organization organization data object
     */

    SET_ORGANIZATION(state, organization) {
      state.organization = organization;
    },

    /**
     * Sets organization name to state.
     *
     * @param {*} state
     * @param {String} organizationName organization name
     */

    SET_ORGANIZATION_NAME(state, organizationName) {
      state.organization["name"] = organizationName;
    },

    /**
     * Sets organization url-name to state.
     *
     * @param {*} state
     * @param {String} urlName organization url-name
     */

    SET_ORGANIZATION_URL_NAME(state, urlName) {
      state.organization["urlName"] = urlName;
    },

    /**
     * Sets organization locale to state.
     *
     * @param {*} state
     * @param {String} locale organization locale
     */

    SET_ORGANIZATION_LOCALE(state, locale) {
      state.organization["locale"] = locale;
    },

    SET_ORGANIZATION_MY_FILES(state, value) {
      state.organization["myFiles"] = value;
    },

    REMOVE_ORGANIZATION_IDENTITY_PROVIDER(state, id) {
      for(var i = 0; i < state.identityProviders.length; ++i) {
        if(state.identityProviders[i].id == id) {
          state.identityProviders.splice(i,1);
          return;
        }
      }
    },

    ADD_OR_UPDATE_ORGANIZATION_IDENTITY_PROVIDER(state,identityProvider) {
      for(let i = 0; i < state.identityProviders.length; ++i) {
        if(state.identityProviders[i].id == identityProvider.id) {
          state.identityProviders.splice(i,1,identityProvider); // Insert at same position as before
          return;
        }
      }
      // Else
      state.identityProviders.unshift(identityProvider); // Prepend as the UI shows the add dialog above the list
    },

     ADD_OR_UPDATE_ORGANIZATION_SUBSCRIPTION(state,subscription) {
      for(let i = 0; i < state.subscriptions.length; ++i) {
        if(state.subscriptions[i].id == subscription.id) {
          state.subscriptions.splice(i,1,subscription); // Insert at same position as before
          return;
        }
      }
      // Else
      state.subscriptions.unshift(subscription);
    },

    REMOVE_ORGANIZATION_STORAGE_INTEGRATION(state, id) {
      for(var i = 0; i < state.storageIntegrations.length; ++i) {
        if(state.storageIntegrations[i].id === id) {
          state.storageIntegrations.splice(i,1);
          return;
        }
      }
    },

    ADD_OR_UPDATE_ORGANIZATION_STORAGE_INTEGRATION(state,integration) {
      for(let i = 0; i < state.storageIntegrations.length; ++i) {
        if(state.storageIntegrations[i].id === integration.id) {
          // Insert at same position as before (update).

          state.storageIntegrations.splice(i,1,integration);
          return;
        }
      }
      // Else: prepend as the UI shows the add dialog above the list.
      state.storageIntegrations.unshift(integration);
    },

    ADD_OR_UPDATE_ORGANIZATION_EXTERNAL_API_KEY(state,externalApiKey) {
      for(let i = 0; i < state.externalApiKeys.length; ++i) {
        let oldApiKey = state.externalApiKeys[i];
        if(oldApiKey.accessToken === externalApiKey.accessToken) {
          // Insert at same position as before (update).

          state.externalApiKeys.splice(i,1,externalApiKey);

          if(oldApiKey.fullApiKey && !externalApiKey.fullApiKey) {
            // If new one does not include full api key, still preserve the information
            externalApiKey.fullApiKey = oldApiKey.fullApiKey;
          }

          return;
        }
      }

      state.externalApiKeys.push(externalApiKey);
      console.log("Added",externalApiKey);
    },

    UPDATE_ORGANIZATION_SUBSCRIPTION_PROPERTY(state, params){

      let subscriptions = this.getters.getOrganizationSubscriptions;
      let sub = subscriptions.find(sub => sub.id == params.organizationSubscriptionId);

      if(params.email)
        sub.email = params.email;

      if(params.status)
        sub.status=params.status;
      if(params.endDate)
        sub.endDate = params.endDate;
    },

    SET_SUBSCRIPTION_LIMITS(state, limits) {
      state.subscriptionLimits = limits;
    },

    /**
     * Sets array of groups in organization to state.
     *
     * Expected parameters:
     *
     * groups = [{
     *   "id": string,
     *   "name": string
     * }]
     *
     * @param {*} state
     * @param {Array} groups organization groups
     */

    SET_ORGANIZATION_GROUPS(state, groups) {
      state.organizationGroups = groups;
    },

    /**
     * Sets the organization identification settings to state.
     *
     * Expected parameters:
     *
     * isIdentificationEnabled = true/false
     *
     * @param {*} state
     * @param {Boolean} if identification is enabled at organization
     */

    SET_ORGANIZATION_IDENTIFICATION_SETTINGS(state, value) {
      state.isIdentificationEnabled = value;
    },

    /**
     * Sets the organization Signature page template to state.
     *
     *
     * @param {*} state
     * @param {Object} organization Signature page data object
     */

    SET_ORGANIZATION_SIGNATURE_PAGE(state, params) {
      state.signaturePageTemplate = params;
    },

    SET_ORGANIZATION_SIGNATURE_PAGE_LOGO(state, logoUrl) {
      state.signaturePageTemplate["logoUrl"] = logoUrl;
    },

    SET_ORGANIZATION_TASK_SHARING_AND_MENTION(state, value) {
      state.organization["taskSharingAndMention"] = value;
    },

    /**
     * Resets the organization information from state.
     */
    RESET_ORGANIZATION_INFO(state) {
      state.organization = {};
      state.identityProviders = [];
      state.storageIntegrations = [];
      state.externalApiKeys = [];
      state.subscriptions = [];
      state.subscriptionLimits = {};
      state.organizationGroups = [];
      state.isIdentificationEnabled = false;
      state.signaturePageTemplate = {};
    },

    SET_ORGANIZATION_SALESFORCE_INTEGRATION(state, value) {
      state.salesforceIntegrationUser = value;
    },

    REMOVE_ORGANIZATION_SALESFORCE_INTEGRATION(state, value) {
      for(var i = 0; i < state.salesforceIntegrationUser.length; ++i) {
        if(state.salesforceIntegrationUser[i].integrationTarget === value) {
          state.salesforceIntegrationUser.splice(i,1);
          return;
        }
      }
    },

    REMOVE_ORGANIZATION_EXTERNAL_API_KEY(state,apikey) {
      for(var i = 0; i < state.externalApiKeys.length; ++i) {
        if(state.externalApiKeys[i].accessToken == apikey.accessToken) {
          state.externalApiKeys.splice(i,1);
          return;
        }
      }
    },

    SET_ENFORCE_IDENTITY_PROVIDER(state, value) {
      state.organization["enforceIdentityProvider"] = value;
    }
  },

  actions: {

    /**
     * Loads Organization data from API and then put the data in Store.
     *
     * The action returns Promise so that the component that use it can decide
     * what to do if the API call fails.
     *
     * How to use:
     * this.$store.dispatch("loadOrganizationData", organizationId);
     *
     * @param {String} organizationId organization identity (may be
     *                                null/undefined, in that case reloads the
     *                                current organization)
     * @returns {Promise} promise
     */
    async loadOrganizationData(context, organizationId) {

      // TODO: This currently loads only the public organization data. It
      //       probably be parametrized that also the full organization data
      //       could be loaded. Maybe we should check current user's user role
      //       and if it is "organization admin" then we load all data.

      // If no organizationId is given, we consider this a "reload" request.

      if(!organizationId)
        organizationId = context.getters.getOrganizationId;

      try {
        const result = await apiCall.get('v1/organizations/' + organizationId + '/public');
        logger.debug("Query loadOrganizationData succeed:",result);
        context.commit("SET_ORGANIZATION", result.data);
        return result;
      }
      catch(error) {
        logger.error("Query loadOrganizationData failed:",error);
        throw error;
      }
    },

    /**
     * Sets the current Organization name.
     *
     * Note that the Organization data needs to be loaded earlier using
     * 'loadOrganizationData' method.
     *
     * How to use:
     * this.$store.dispatch("setOrganizationName", organizationName);
     *
     * @param {String} organizationName new organization name
     *
     * @returns {Promise} promise
     */
    async setOrganizationName(context, organizationName) {

      let organizationId = context.getters.getOrganizationId;

      if(!organizationId) {
        logger.error("setOrganizationName: Identity missing!");
        return;
      }

      let params = {
        "organizationName" : organizationName
      };

      // Eagerly set the name to State. It will be reverted if the actual
      // call to set the name fails.

      context.commit("SET_ORGANIZATION_NAME",organizationName);

      let url = 'v1/organizations/' + organizationId + '/name';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("Organization name update succeeded:",result);
        context.commit("SET_USER_ORGANIZATION",context.state.organization);
        return result;
      }
      catch(error) {
        logger.error("Organization name update failed:",error);

        context.dispatch("loadOrganizationData",organizationId);

        throw error;
      }
    },


    /**
     * Sets the current Organization url-name.
     *
     * Note that the Organization data needs to be loaded earlier using
     * 'loadOrganizationData' method.
     *
     *
     * @param {String} organizationUrlName new organization url-name
     *
     * @returns {Promise} promise
     */
    async setOrganizationUrlName(context, urlName) {

      let organizationId = context.getters.getOrganizationId;

      if(!organizationId) {
        logger.error("setOrganizationUrlName: Identity missing!");
        return;
      }

      let params = {
        "organizationUrlName" : urlName
      };

      // Eagerly set the name to State. It will be reverted if the actual
      // call to set the name fails.

      context.commit("SET_ORGANIZATION_URL_NAME",urlName);

      let url = 'v1/organizations/' + organizationId + '/url-name';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("Organization url-name update succeeded:",result);
        context.commit("SET_USER_ORGANIZATION",context.state.organization);
        return result;
      }
      catch(error) {
        logger.error("Organization url-name update failed:",error);

        context.dispatch("loadOrganizationData",organizationId);

        throw error;
      }
    },

     /**
     * Sets the current Organization locale.
     *
     *
     * @param {String} locale new locale
     *
     * @returns {Promise} promise
     */
    async setOrganizationLocale(context, locale) {

      let organizationId = context.getters.getOrganizationId;

      if(!organizationId) {
        logger.error("setOrganizationLocale: Identity missing!");
        return;
      }

      let params = {
        "locale" : locale
      };

      // Eagerly set the locale to State. It will be reverted if the API call fails.

      context.commit("SET_ORGANIZATION_LOCALE", locale);

      let url = 'v1/organizations/' + organizationId + '/locale';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("Organization locale update succeeded:",result);
        context.commit("SET_USER_ORGANIZATION",context.state.organization);
        return result;
      }
      catch(error) {
        logger.error("Organization locale update failed:",error);

        context.dispatch("loadOrganizationData",organizationId);

        throw error;
      }
    },

    /**
     * Sets the current Organization myFiles property.
     *
     *
     * @param {boolean} value new value
     *
     * @returns {Promise} promise
     */
    async setOrganizationMyFiles(context, value) {

      let organizationId = context.getters.getOrganizationId;

      if (!organizationId) {
        logger.error("setOrganizationLocale: Identity missing!");
        return;
      }

      let params = {
        "myFiles": value
      };


      context.commit("SET_ORGANIZATION_MY_FILES", value);

      let url = 'v1/organizations/' + organizationId + '/my-files';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("Organization myfiles update succeeded:", result);
        return result;
      } catch (error) {
        logger.error("Organization myfiles update failed:", error);

        context.dispatch("loadOrganizationData", organizationId);

        throw error;
      }
    },

    async setOrganizationTaskSharingAndMention(context, value) {

      let organizationId = context.getters.getOrganizationId;

      let params = {
        "taskSharingAndMention": value
      };

      context.commit("SET_ORGANIZATION_TASK_SHARING_AND_MENTION", value);

      let url = 'v1/organizations/task-sharing-and-mention';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("Organization taskSharingAndMention update succeeded:", result);
        return result;
      } catch (error) {
        logger.error("Organization taskSharingAndMention update failed:", error);

        context.dispatch("loadOrganizationData", organizationId);

        throw error;
      }
    },

    async createOrganizationIdentityProvider(context,identityProvider) {
      let organizationId = context.getters.getOrganizationId;

      let url = 'v1/organizations/' + organizationId + '/identityProviders';

      try {
        const result = await apiCall.put(url, identityProvider);
        logger.debug("Create identity provider succeeded:",result);
        context.commit("ADD_OR_UPDATE_ORGANIZATION_IDENTITY_PROVIDER",result.data);
        return result;
      }
      catch(error) {
        logger.error("Create identity provider failed:",error);

        throw error;
      }
    },

    async createOrganizationSubscription(context,params) {

      let url = 'v1/organizations/subscription';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("Create organization subscription succeeded:",result);
        context.commit("ADD_OR_UPDATE_ORGANIZATION_SUBSCRIPTION",result.data);
        return result;
      }
      catch(error) {
        logger.error("Create  organization subscription failed:",error);
        this.dispatch("loadOrganizations");
        throw error;
      }
    },


    async removeOrganizationIdentityProvider(context,id) {
      let organizationId = context.getters.getOrganizationId;

      let url = 'v1/organizations/' + organizationId + '/identityProviders/' + id;

      context.commit("REMOVE_ORGANIZATION_IDENTITY_PROVIDER",id);

      try {
        const result = await apiCall.delete(url)
        logger.debug("Deleting identity provider succeeded:",result);

        return result;
      }
      catch(error) {
        logger.error("Deleting identity provider failed:",error);

        throw error;

      }
    },

    /**
     * Removes a storage integration from back-end.
     *
     * Expected parameters:
     * {
     *   integrationId: String (identity of the integration to delete)
     *   type: String (integeration type)
     * }
     *
     * @param {*} context
     * @param {*} params parameters
     */
    async removeOrganizationStorageIntegration(context,params) {

      let integrationId = params.integrationId;
      let type = params.type;

      let url = 'v1/storages/' + type + '/' + integrationId;

      try {
        const result = await apiCall.delete(url);
        logger.debug("Deleting storage integration succeeded:",result);

        return result;
      }
      catch(error) {
        logger.error("Deleting storage integration failed:",error);

        throw error;
      }
    },

    /**
     * Updates a storage integration in back-end.
     *
     * Expected parameters:
     * {
     *   integrationId: String (identity of the integration to update)
     *   type: String (integeration type),
     *   enabled: Boolean (whether the integration is enabled)
     * }
     *
     * @param {*} context
     * @param {*} params parameters
     */
    async updateOrganizationStorageIntegration(context,params) {

      let integrationId = params.integrationId;
      let type = params.type;

      let apiParams = {
        enabled : params.enabled
      };

      let url = 'v1/storages/' + type + '/' + integrationId;

      try {
        const result = await apiCall.patch(url,apiParams);
        logger.debug("Updating storage integration succeeded:",result);

        return result;
      }
      catch(error) {
        logger.error("Updating storage integration failed:",error);

        throw error;
      }
    },

    async saveOrganizationIdentityProvider(context, identityProvider) {

      let organizationId = context.getters.getOrganizationId;

      let url = 'v1/organizations/' + organizationId + '/identityProviders/' + identityProvider.id;

      try {
        const result = await apiCall.patch(url, identityProvider)
        logger.debug("Create identity provider succeeded:",result);

        context.commit("ADD_OR_UPDATE_ORGANIZATION_IDENTITY_PROVIDER",identityProvider);
        return result;
      }
      catch(error) {
        logger.error("Create identity provider failed:",error);

        throw error;
      }
    },

    async loadOrganizationIdentityProviders(context) {
      let organizationId = context.getters.getOrganizationId;

      let url = 'v1/organizations/' + organizationId + '/identityProviders';

      try {
        const result = await apiCall.get(url)
        logger.debug("Listing identity providers succeeded:",result);

        let identityProviders = result.data.identityProviders

        for(let i = 0; i < identityProviders.length; ++i) {
          context.commit("ADD_OR_UPDATE_ORGANIZATION_IDENTITY_PROVIDER",identityProviders[i]);
        }

        return result;
      }
      catch(error) {
        logger.error("Listing identity providers failed:",error);

        throw error;
      }
    },

    async loadOrganizationSubscriptions(context) {
      let organizationId = context.getters.getOrganizationId;

      let url = 'v1/organizations/' + organizationId + '/subscriptions';

      try {
        const result = await apiCall.get(url)
        logger.debug("Listing subscriptions succeeded:",result);

        let subscriptions = result.data
        for(let i = 0; i < subscriptions.length; ++i) {
          context.commit("ADD_OR_UPDATE_ORGANIZATION_SUBSCRIPTION",subscriptions[i]);
        }
        return subscriptions;
      }
      catch(error) {
        logger.error("Listing subscriptions failed:",error);

        throw error;
      }
    },

    async loadOrganizationStorageIntegrations(context) {
      let organizationId = context.getters.getOrganizationId;

      let url = 'v1/storages/' + organizationId;

      try {
        const result = await apiCall.get(url)
        logger.debug("Listing storage integrations succeeded:",result);

        let storageIntegrations = result.data;

        for(let i = 0; i < storageIntegrations.length; ++i) {
          context.commit("ADD_OR_UPDATE_ORGANIZATION_STORAGE_INTEGRATION",
                         storageIntegrations[i]);
        }

        return result;
      }
      catch(error) {
        logger.error("Listing storage integrations failed:",error);

        throw error;
      }
    },

    async loadOrganizationExternalApiKeys(context) {

      // This result does not contain the secret tokens, as those are hashed
      // in Custodian like passwords.

      let url = 'v1/organizations/my-organization/external-use/api-keys';

      try {
        const result = await apiCall.get(url)
        logger.debug("Listing external API keys succeeded:",result);

        let apiKeys = result.data.apiKeys;
        for(let i = 0; i < apiKeys.length; ++i) {

          context.commit("ADD_OR_UPDATE_ORGANIZATION_EXTERNAL_API_KEY",
                         apiKeys[i]);
        }

        return result;
      }
      catch(error) {
        logger.error("Listing external API keys failed:",error);

        throw error;
      }
    },

    async createOrganizationExternalApiKey(context) {
      let url = 'v1/organizations/my-organization/external-use/api-keys/';

      try {
        let result = await apiCall.put(url);
        logger.debug("Creating external API key succeeded:");

        // Reload

        let apiKey = result.data;

        context.commit("ADD_OR_UPDATE_ORGANIZATION_EXTERNAL_API_KEY",
                       apiKey);

        return apiKey;
      }
      catch(error) {
        logger.error("Creating external API key failed:",error);

        throw error;
      }
    },

    async deactivateOrganizationExternalApiKey(context,externalApiKey) {
      let url = 'v1/organizations/my-organization/external-use/api-keys/'+encodeURIComponent(externalApiKey.accessToken);

      context.commit("REMOVE_ORGANIZATION_EXTERNAL_API_KEY",externalApiKey);

      try {
        await apiCall.delete(url);
        logger.debug("Deactivating external API key succeeded:");

        // Reload

        let result = context.dispatch("loadOrganizationExternalApiKeys");

        return result;
      }
      catch(error) {
        logger.error("Deactivating external API key failed:",error);

        throw error;
      }
    },

    /**
     * Invite user to organization.
     *
     * params: {
     *  "email": string,
     *  "groupIds": List of group identities
     * }
     *
     * @param {*} context
     * @param {*} params
     * @returns
     */

    async inviteOrganizationUser(context, params) {

      // Set the organization identity to the request.
      let organizationId = context.getters.organizationId;
      params.organizationId = organizationId;

      let url = 'v1/invitation/organization/user';

      try {
        const result = await apiCall.put(url,params)
        logger.debug("User invitation succeeded:",result);
        return result;
      }
      catch(error) {
        logger.error("User invitation failed:",error);

        throw error;
      }
    },

    /**
     * Re-invite user to organization.
     *
     * params: {
     *  "userId": string
     * }
     *
     * @param {*} context
     * @param {*} params
     * @returns
     */

    async reInviteOrganizationUser(context, params) {

      let url = 'v1/invitation/organization/user/re-invite';

      try {
        const result = await apiCall.put(url,params)
        logger.debug("User invitation succeeded:",result);
        return result;
      }
      catch(error) {
        logger.error("User invitation failed:",error);

        throw error;
      }
    },

    async cancelOrganizationSubscription(context,id) {

      let url = 'v1/organizations/subscription/'+id +"/cancel";

      try {
        const result = await apiCall.put(url);
        logger.debug("cancelOrganizationSubscription succeeded:",result);
        context.commit("ADD_OR_UPDATE_ORGANIZATION_SUBSCRIPTION",result.data);
        return result;
      }
      catch(error) {
        logger.error("cancelOrganizationSubscription failed:",error);

        throw error;
      }
    },

    async updateOrganizationSubscriptionEmail(context, params) {

      // Update store state immediately to prevent UI flickering between old and new value.
      context.commit("UPDATE_ORGANIZATION_SUBSCRIPTION_PROPERTY",params);

      await apiCall.patch("v1/organizations/organization-subscription/email", params)
        .then(result => {
        logger.debug("updateOrganizationSubscriptionEmail succeeded with result:", result);
      })
      .catch(error => {
        logger.error("updateOrganizationSubscriptionEmail didn't succeed with message:", error);
        this.dispatch("loadOrganizations");
      });
    },

    async requestCustomSubscription(context, params) {

      // Update store state immediately to prevent UI flickering between old and new value.

      await apiCall.patch("v1/organizations/subscription/custom", params)
        .then(result => {
        logger.debug("requestCustomSubscription succeeded with result:", result);
      })
      .catch(error => {
        logger.error("requestCustomSubscription didn't succeed with message:", error);
        this.dispatch("loadOrganizations");
      });
    },

    async loadSubscriptionLimits(context, organizationId) {

      let url = 'v1/organizations/' + organizationId + '/subscriptionLimits';

      try {
        const result = await apiCall.get(url)
        logger.debug("Listing subscription plan limits succeeded:",result);
        let limits = result.data
        context.commit("SET_SUBSCRIPTION_LIMITS", limits);
        return limits;
      }
      catch(error) {
        logger.error("Listing subscription plan limits failed:",error);
        throw error;
      }
    },

    async getOrganizationGroups(context) {
      let url = 'v1/groups/organization/list';
      try {
        const result = await apiCall.get(url);
        logger.debug("Get organization groups succeeded:",result);
        context.commit("SET_ORGANIZATION_GROUPS",result.data);
        return result.data;
      }
      catch(error) {
        logger.error("Get organization groups failed:",error);
        throw error;
      }
    },

    /**
     * Loads identification settings.
     */
    async loadIdentificationSettings(context) {
      apiCall.get("v1/organizations/identificationSettingsPublic")
        .then(response => {
          context.commit("SET_ORGANIZATION_IDENTIFICATION_SETTINGS",response.data.identificationEnabled);
        })
          .catch(error => {
            console.error("Failed to load identification settings.",error);
          });
    },

    /**
     * Loads Organization Signature page template data from API
     * and then put the data in Store.
     *
     * The action returns Promise so that the component that use it can decide
     * what to do if the API call fails.
     *
     * How to use:
     * this.$store.dispatch("loadOrganizationSignaturePage");
     *
     * @returns {Promise} promise
     */
    async loadOrganizationSignaturePage(context) {

      try {
        await apiCall.get('v1/signature-page/get')
          .then(async result =>  {
            context.commit("SET_ORGANIZATION_SIGNATURE_PAGE", result.data);
            if(result.data.organizationLogo) {
              let response = await apiCall.get("v1/signature-page/organization-logo", { responseType: "blob" })
              let blob = new Blob([response.data], {type: response.data.type});
              let logoUrl = window.URL.createObjectURL(blob);

              context.commit("SET_ORGANIZATION_SIGNATURE_PAGE_LOGO", logoUrl);
            }
          })
      }
      catch(error) {
        logger.error("Query loadOrganizationSignaturePage failed:",error);
        throw error;
      }
    },

    /**
     * Updates Organization Signature page template data in database
     * and then put the data in Store.
     *
     * How to use:
     * this.$store.dispatch("updateOrganizationSignaturePage", params);
     *
     * @returns {Promise} promise
     */

    async updateOrganizationSignaturePage(context, params) {
      try {
        const result = await apiCall.put("v1/signature-page/update-template", params);
        logger.debug("updateOrganizationSignaturePage succeeded with result:", result);
      }
      catch(error) {
        logger.error("updateOrganizationSignaturePage didn't succeed with message:", error);
        this.dispatch("loadOrganizationSignaturePage");
      }
    },

    /**
     * Upload Organization logo for Signature page template
     * and then put the data in Store.
     *
     * How to use:
     * this.$store.dispatch("uploadOrganizationLogo", formData);
     *
     * @returns {Promise} promise
     */
    async uploadOrganizationLogo(context, formData) {
      try {
        const result = await apiCall.put("v1/signature-page/upload-logo", formData);
        logger.debug("uploadOrganizationLogo succeeded with result:", result);
        this.dispatch("loadOrganizationSignaturePage");
      }
      catch(error) {
        logger.error("uploadOrganizationLogo didn't succeed with message:", error);
        this.dispatch("loadOrganizationSignaturePage");
      }
    },

    /**
     * Delete Organization logo from Signature page template
     *
     * How to use:
     * this.$store.dispatch("deleteOrganizationLogo");
     *
     * @returns {Promise} promise
     */
     async deleteOrganizationLogo(context) {
      try {
        const result = await apiCall.delete("v1/signature-page/delete-logo");
        context.commit("SET_ORGANIZATION_SIGNATURE_PAGE_LOGO", null);
        logger.debug("deleteOrganizationLogo succeeded with result:", result);
      }
      catch(error) {
        logger.error("deleteOrganizationLogo didn't succeed with message:", error);
        this.dispatch("loadOrganizationSignaturePage");
      }
    },

    /**
     * Clears organization data from state.
     */
    clearOrgInfo(context) {
      context.commit("RESET_ORGANIZATION_INFO");
    },

    /**
     * Loads organization Salesforce integration user info.
     *
     * This endpoint can be called only by organization admins. The results
     * include integration targets and their associated usernames.
     */
    async loadSalesforceIntegration(context) {

      let url = 'v1/organizations/integration-user';

      try {
        const result = await apiCall.get(url)
        logger.debug("Get Salesforce integration user succeeded:",result);

        const integrationUsers = result.data.integrationUsers;

        context.commit("SET_ORGANIZATION_SALESFORCE_INTEGRATION",integrationUsers);

        return integrationUsers;
      }
      catch(error) {
        logger.error("Getting Salesforce integration user failed:",error);

        throw error;
      }
    },

    /**
     * Loads organization Salesforce integration targets info.
     *
     * This endpoint can be called by all users. The results include
     * only configured integration targets.
     */
    async loadSalesforceIntegrationTargets(context) {

      let url = 'v1/organizations/integration-user/targets';

      try {
        const result = await apiCall.get(url)
        logger.debug("Get Salesforce integration targets succeeded:",result);

        const integrationTargets = result.data.integrationTargets;

        //context.commit("SET_ORGANIZATION_SALESFORCE_INTEGRATION",integrationUsers); // Not needed as of writing this

        return integrationTargets;
      }
      catch(error) {
        logger.error("Getting Salesforce integration targets failed:",error);

        throw error;
      }
    },

    /**
     * Creates organization Salesforce integration user.
     */
    async createSalesforceIntegration(context, params) {

      let url = 'v1/organizations/integration-user';

      try {
        await apiCall.post(url, params)
        logger.debug("Salesforce integration user created");

        // Load user info to update the state.
        context.dispatch("loadSalesforceIntegration");
      }
      catch(error) {
        logger.error("Creating Salesforce integration user failed:",error);

        throw error;
      }
    },

    /**
     * Removes organization Salesforce integration user.
     */
    async deleteSalesforceIntegration(context, integrationTarget) {

      let url = `v1/organizations/integration-user/${integrationTarget}`;

      try {
        await apiCall.delete(url);
        logger.debug("Salesforce integration user deleted");

        // Remove integration user info to update the state.
        context.commit("REMOVE_ORGANIZATION_SALESFORCE_INTEGRATION", integrationTarget);
      }
      catch(error) {
        logger.error("Deleting Salesforce integration user failed:",error);

        throw error;
      }
    },

    /**
     * Sets whether to enforce logging in with the specified identity providers.
     *
     * Note that the Organization data needs to be loaded earlier using
     * 'loadOrganizationData' method.
     *
     * How to use:
     * this.$store.dispatch("setEnforceIdentityProvider", enforce);
     *
     * @param {boolean} enforce if to enforce logging in only with identity providers
     *
     * @returns {Promise} promise
     */
    async setEnforceIdentityProvider(context, enforce) {

      let organizationId = context.getters.getOrganizationId;

      if(!organizationId) {
        logger.error("setEnforceIdentityProvider: Identity missing!");
        return;
      }

      let params = {
        "enforce": enforce,
      };

      // Eagerly set the name to State. It will be reverted if the actual
      // call to set the name fails.

      context.commit("SET_ENFORCE_IDENTITY_PROVIDER",enforce);

      let url = 'v1/organizations/' + organizationId + '/enforce-identity-provider';

      try {
        const result = await apiCall.put(url, params);
        logger.debug("Setting enforce identity provider succeeded:",result);
        return result;
      }
      catch(error) {
        logger.error("Setting enforce identity provider failed:",error);

        context.dispatch("loadOrganizationData",organizationId);

        throw error;
      }
    },
  }
}
