/**
 * Collection of task related functions and constants.
 */

import i18n from './../i18n';

// --- Constants ---

/**
 * Task rejection resolvation strategy enumeration.
 *
 * Note: This should match TaskResolvationStrategy enumeration in back-end.
 */
const RESOLVATION_STRATEGY = Object.freeze({
  CANCEL_REJECTION: "CANCEL_REJECTION",
  CHANGE_DOCUMENT: "CHANGE_DOCUMENT",
  CHANGE_DOCUMENT_AND_REVERT_TO_STEP: "CHANGE_DOCUMENT_AND_REVERT_TO_STEP",
  REASSIGN_TO_COLLABORATOR: "REASSIGN_TO_COLLABORATOR",
  REVERT_TO_STEP: "REVERT_TO_STEP",
  SKIP_TASK: "SKIP_TASK", // TODO: Consider renaming to "SKIP_STEP".
  RESOLVE_BY_MYSELF: "RESOLVE_BY_MYSELF"
});

/**
 * Task resolving rejection options.
 */
const RESOLVING_OPTION = Object.freeze({
  SELECT_STEP: "SELECT_STEP",
  SELECT_FILE_STEP: "SELECT_FILE_STEP",
  REASSIGN: "REASSIGN",
  RESOLVE_MYSELF: "RESOLVE_MYSELF",
  SKIP_TASK: "SKIP_TASK" // TODO: Consider renaming to "SKIP_STEP".
})

/**
 * Task status enumeration.
 *
 * Note: This should match TaskStatus enumeration in back-end.
 */
const STATUS = Object.freeze({
  AVAILABLE: "AVAILABLE",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  PAUSED: "PAUSED",
  CANCELED: "CANCELED",
  REJECTED: "REJECTED",
  UPCOMING: "UPCOMING"
});

// --- Public functions ---

/**
 * Maps a Task status to a UI friendly string.
 *
 * @param status task status (may be null)
 *
 * @returns {String} UI friendly text representation of the status
 */

function statusToText(status) {
  if(status == null)
    return "";

  switch(status) {
    case STATUS.AVAILABLE: return i18n.global.t("task.status.available");
    case STATUS.IN_PROGRESS: return i18n.global.t("task.status.in_progress");
    case STATUS.COMPLETED: return i18n.global.t("task.status.completed");
    case STATUS.PAUSED: return i18n.global.t("task.status.paused");
    case STATUS.CANCELED: return i18n.global.t("task.status.canceled");
    case STATUS.REJECTED: return i18n.global.t("task.status.rejected");
    case STATUS.UPCOMING: return i18n.global.t("task.status.upcoming");
    default: return "";
  }
}

/**
 * Maps a Task resolving option to a UI friendly string.
 *
 * @param option rejection resolving option
 *
 * @returns {String} UI friendly text representation of the resolving option
 */
function resolvingOptionToText(option) {
  switch(option) {
    case RESOLVING_OPTION.SELECT_STEP:
      return i18n.global.t('project_tasks.dialog.address_request.start_resolve.select_step.title');
    case RESOLVING_OPTION.SELECT_FILE_STEP:
      return i18n.global.t('project_tasks.dialog.address_request.start_resolve.select_file_step.title');
    case RESOLVING_OPTION.REASSIGN:
      return i18n.global.t('project_tasks.dialog.address_request.start_resolve.reassign.title');
    case RESOLVING_OPTION.RESOLVE_MYSELF:
      return i18n.global.t('project_tasks.dialog.address_request.start_resolve.resolve_myself.title');
    case RESOLVING_OPTION.SKIP_TASK:
      return i18n.global.t('project_tasks.dialog.address_request.start_resolve.skip_task.title');
    default:
      return;
  }
}

export default {
  // --- Constants ---

  RESOLVATION_STRATEGY,
  STATUS,
  RESOLVING_OPTION,

  // --- Functions ---

  statusToText,
  resolvingOptionToText
}
